import { useContext } from "react";
import { AuthContext } from "src/context/auth";
import { Route, Redirect } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import { PrivateRoute } from "./PrivateRoute";
import DashboardTabs from "./DashboardTabs";

import Login from "src/pages/Login";
import Task from "src/pages/Task";
import Team from "src/pages/Team";
import Network from "src/pages/Network";
import Contacts from "src/pages/Contacts";
import Other from "src/pages/Other";
import ProfileQuestions from "src/pages/ProfileQuestions";
import Stage from "src/pages/Stage";
import Feed from "src/pages/Feed";
import Qr from "src/pages/Qr";
import ForgotPassword from "src/pages/ForgotPassword";
import NotificationSettings from "src/pages/NotificationSettings";
import Checklist from "src/pages/Checklist";
import Test from "src/pages/Test";
import Welcome from "src/pages/Welcome";
import Rewards from "src/pages/Rewards";

// TODO: add these routes back
// import PostOnboarding from "src/pages/PostOnboarding";
// import AddPost from "src/pages/AddPost";

function Routes() {
  const { user, isLoading } = useContext(AuthContext);

  // Wait on the user fetch
  if (isLoading) return null;

  return (
    <IonRouterOutlet animated={false}>
      <>
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/login"
          render={(props) =>
            !user ? (
              <Login {...props} />
            ) : (
              <Redirect exact from="/login" to="/dashboard/home" />
            )
          }
        />
        <Route
          exact
          path="/"
          render={() =>
            !user ? (
              <Redirect exact from="/" to="/login" />
            ) : (
              <Redirect exact from="/" to="/dashboard/home" />
            )
          }
        />
        <PrivateRoute exact path="/welcome" component={Welcome} />
        <PrivateRoute
          exact
          path="/profile-questions"
          component={ProfileQuestions}
        />
        <PrivateRoute exact path="/reward" component={Rewards} />
        <PrivateRoute exact path="/onboarding/task/:id" component={Task} />
        <PrivateRoute exact path="/onboarding/test/:id" component={Test} />
        <PrivateRoute exact path="/network" component={Network} />
        <PrivateRoute exact path="/network/team" component={Team} />
        <PrivateRoute exact path="/network/contacts" component={Contacts} />
        <PrivateRoute exact path="/network/other" component={Other} />
        <PrivateRoute exact path="/postOnboarding/feed/:id" component={Feed} />
        <PrivateRoute exact path="/onboarding/stage/:id" component={Stage} />
        <PrivateRoute exact path="/checklist" component={Checklist} />
        <PrivateRoute path="/dashboard" component={DashboardTabs} />
        <PrivateRoute exact path="/dashboard/qr" component={Qr} />
        <PrivateRoute
          exact
          path="/notifications/settings"
          component={NotificationSettings}
        />
      </>
    </IonRouterOutlet>
  );
}

export default Routes;
