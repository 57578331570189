import { RouteComponentProps } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "src/context/auth";
import { IonContent, IonPage, IonButton, IonRouterLink } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Header } from "src/components/Ui";

const Settings: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { setUser, isLoading } = useContext(AuthContext);
  const signOut = async () => {
    localStorage.removeItem("sess");
    setUser(null);
    if (!isLoading) history.push("/login");
  };

  return (
    <IonPage>
      <Header title={"Instellingen"}></Header>
      <IonContent fullscreen>
        <div className="container mx-auto max-w-5xl p-4">
          <IonButton className="btn-primary w-full">
            <IonRouterLink
              className="w-full h-full flex items-center justify-center text-white"
              href="https://admin.custobo.com/users/password/new"
            >
              Wachtwoord vergeten
            </IonRouterLink>
          </IonButton>
          <IonButton className="btn-secondary w-full">
            <IonRouterLink
              className="w-full h-full flex items-center justify-center text-black"
              href="https://web-cms.custobo.com/wp-content/uploads/2017/11/CustoboBV_PrivacyVerklaring.pdf"
            >
              {t("settings.privacy_statement")}
            </IonRouterLink>
          </IonButton>
          <IonButton className="btn-secondary w-full">
            <IonRouterLink
              className="w-full h-full flex items-center justify-center text-black"
              href="mailto:guido@custobo.com?subject=%5BCustobo%20App%5D%20Verwijder%20mijn%20account&body=Beste%20heer%2Fmevrouw%2C%0A%0AWilt%20u%20mijn%20Custobo%20App%20account%20verwijderen%3F%0A%0AGraag%20ontvang%20ik%20hier%20een%20bevestiging%20van.%20Bij%20voorbaat%20dank.%0A%0AVriendelijke%20groet%2C"
            >
              {t("settings.delete_account")}
            </IonRouterLink>
          </IonButton>
          <IonButton className="btn-secondary w-full">
            <IonRouterLink
              routerLink="/notifications/settings"
              className="w-full h-full flex items-center justify-center text-black"
            >
              {t("settings.notification_preferences")}
            </IonRouterLink>
          </IonButton>
          <IonButton
            className="btn-primary"
            style={{ width: "100%" }}
            onClick={() => signOut()}
          >
            {t("settings.logout")}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
