import type { TextFieldTypes } from "@ionic/core";
import { IonInput, IonLabel } from "@ionic/react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

interface InputProps {
  control: Control<any>; // any because the field value generic from the parent won't be available
  name: string;
  placeholder?: string;
  value?: string;
  type?: TextFieldTypes;
  className?: string;
  rules?: RegisterOptions;
  error?: string;
  label?: string;
  labelPosition?: "fixed" | "floating" | "stacked";
}

function Input({
  placeholder,
  type = "text",
  control,
  name,
  className,
  rules,
  error,
  label,
  labelPosition = "stacked",
}: InputProps) {
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, ref, value } }) => {
        const args = {
          type,
          name,
          placeholder,
        };

        return (
          <>
            {label && (
              <IonLabel position={labelPosition} color={error && "danger"}>
                {`${label} ${error ? " - " + error : ""}`}
              </IonLabel>
            )}
            <IonInput
              className={className}
              {...args}
              id={name}
              onIonChange={onChange}
              value={value}
              ref={ref}
            />
          </>
        );
      }}
    />
  );
}

export default Input;
