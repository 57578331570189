import { authClient } from "src/utils/api";

class TipsAndTricks {
  // Queries
  static async get(): Promise<Stage[]> {
    const res = await authClient().get("/stages/tips_and_tricks");
    return res.data;
  }
}

export default TipsAndTricks;
