import { IonItem, IonText } from "@ionic/react";
import Showdown from "showdown";

interface UploadVideoTaskProps {
  task: OnboardingTask;
}

const converter = new Showdown.Converter({ tables: true });

function UploadVideoTask({ task }: UploadVideoTaskProps) {
  return (
    <div>
      <IonItem lines="none">
        <IonText
          className="render-html"
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(task.text) }}
        />
      </IonItem>
      <IonItem lines="none">
        <input type="file" />
      </IonItem>
    </div>
  );
}

export default UploadVideoTask;
