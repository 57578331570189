import { RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Onboarding, Profile } from "src/api";
import {
  IonContent,
  IonPage,
  IonButton,
  IonText,
  IonSkeletonText,
  IonItem,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Checkboxes, Select } from "src/components/ProfileForm";
import { Input } from "src/components/Form";
import { useTranslation } from "react-i18next";

const Welcome: React.FC<RouteComponentProps> = ({ history }) => {
  const { data } = useQuery("onboardables", () => Onboarding.onboardables());
  const updateList = useMutation((list: [string, unknown][]) =>
    Profile.updateMany(list)
  );
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();

  const submit = async (data: any) => {
    try {
      const list = Object.entries(data);
      await updateList.mutateAsync(list);
      history.push("/dashboard");
    } catch (error) {
      // TODO: error handling
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="h-full bg-cover bg-white">
          <div className="container p-4 mx-auto max-w-3xl">
            <IonText>
              <h1 className="text-center mb-1">
                {t("profile_questions.title")}
              </h1>
            </IonText>
            <IonText>
              <div className="text-center mb-8 px-4">
                {data && data.length > 0 ? (
                  data[0].profile_questions_description
                ) : (
                  <>
                    <IonSkeletonText />
                    <IonSkeletonText className="w-2/3 mt-2 mx-auto" />
                  </>
                )}
              </div>
            </IonText>

            {data && data.length > 0 && (
              <form
                onSubmit={handleSubmit(submit)}
                className="w-full flex flex-col"
              >
                {data[0].profile_questions.map((q) => (
                  <div key={q.id}>
                    {q.input_type === "checkboxes" && (
                      <IonItem class="ion-no-padding">
                        <Checkboxes control={control} question={q} />
                      </IonItem>
                    )}
                    {q.input_type === "select" && (
                      <IonItem>
                        <Select
                          control={control}
                          className="mb-8"
                          question={q}
                        />
                      </IonItem>
                    )}
                    {q.input_type === "text" && (
                      <IonItem>
                        <Input
                          name={q.id.toString()}
                          label={q.question}
                          control={control}
                        />
                      </IonItem>
                    )}
                  </div>
                ))}
                <IonButton className="btn-primary mt-4" type="submit">
                  Verder
                </IonButton>
              </form>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
