import React, {
  createContext,
  useState,
  useEffect,
  ReactChild,
  ReactChildren,
} from "react";
import { client } from "src/utils/api";
import { isPlatform } from "@ionic/react";
import { AppIcon } from "@capacitor-community/app-icon";

interface AuthContextProps {
  user: User | null;
  isLoading: boolean;
  error: string | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  revalidateSession: () => void;
}

interface AuthProviderProps {
  children: ReactChild | ReactChildren;
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("sess");
        if (!token) return;
        const user = await client("/session", {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });

        // @ts-ignore
        if (!user.error) {
          setUser(user.data);
        }
      } catch (err) {
        setError(err as string);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    if (user) {
      root.style.setProperty("--ion-color-header", user.styles.header_color);
      root.style.setProperty("--ion-color-button", user.styles.button_color);
    } else {
      root.style.setProperty("--ion-background-color", "white");
    }
  }, [user]);

  const revalidateSession = async () => {
    try {
      const token = localStorage.getItem("sess");
      if (!token) return;
      const user = await client("/session", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      // @ts-ignore
      if (!user.error) {
        setUser(user.data);
      }
    } catch (err) {
      setError(err as string);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          if (isPlatform("capacitor") && isPlatform("ios")) {
            const isSupported = await AppIcon.isSupported();

            if (isSupported.value) {
              const name = await AppIcon.getName();
              const n: string = user?.onboardables[0].customer_name
                .replace(/\s+/g, "-")
                .toLowerCase();

              if (n && (!name.value || n !== name.value)) {
                await AppIcon.change({
                  name: n,
                  suppressNotification: true,
                });
              }
            }
          }
        } catch (err) {}
      })();
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{ user, isLoading, error, setUser, revalidateSession }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
