import React, { useEffect, useState, useContext, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IonPage, IonContent } from "@ionic/react";
import { Onboarding } from "src/api";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "src/context/auth";
import RenderTask from "src/components/Tasks";
import {
  chevronBackOutline,
  chevronForwardOutline,
  home,
} from "ionicons/icons";
import { Stages } from "src/api";
import { Text } from "src/components/Ui";
import {
  IonHeader,
  IonText,
  IonIcon,
  IonButton,
  IonRouterLink,
  IonSpinner,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

interface Params {
  id?: string;
}

const Task: React.FC<RouteComponentProps<Params>> = ({ match, history }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [showPreLude, setShowPreLude] = useState<boolean>(false);
  const [showResume, setShowResume] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const { mutateAsync: completeTask } = useMutation((id: string) =>
    Onboarding.completeTask(id)
  );
  const { data: task, isLoading: isTaskLoading } = useQuery(
    ["task", match.params.id],
    () => Onboarding.task(match.params.id),
    { enabled: !!match.params.id, refetchOnWindowFocus: false }
  );
  const { data: stage, isLoading: isStageLoading } = useQuery(
    ["stage", task?.stage_id],
    () => Stages.stage(task?.stage_id as number),
    { enabled: !!task }
  );

  useEffect(() => {
    if (
      task?.id === stage?.tasks[0].id &&
      !!stage?.resume_button &&
      !!stage?.resume_text &&
      !!stage?.resume_title
    ) {
      setShowPreLude(true);
    } else {
      setShowPreLude(false);
    }
    setIndex(stage?.tasks.findIndex((s) => s.id === task?.id) ?? 0);
  }, [task, stage]);

  if (!task || !stage) return null;

  const toNextTask = async () => {
    if (task.completed) {
      history.push(`/onboarding/task/${stage.tasks[index + 1].id}`);
    } else {
      await completeTask(match.params.id as string);
      history.push(`/onboarding/task/${stage.tasks[index + 1].id}`);
    }
  };

  const resume = async () => {
    if (task.completed) {
      setShowResume(true);
    } else {
      await completeTask(match.params.id as string);
      setShowResume(true);
    }
  };

  // TODO:
  // Update task completed every time the user goes to the
  // next task (check if not already completed)

  return (
    <IonPage>
      <IonHeader className="bg-header text-white shadow-none safe-area-top">
        <div className="flex justify-between items-center py-1">
          {index > 0 ? (
            <IonRouterLink
              className="p-2 pb-0 text-white"
              routerLink={`/onboarding/task/${stage.tasks[index - 1].id}`}
            >
              <IonIcon icon={chevronBackOutline} size="large" />
            </IonRouterLink>
          ) : (
            <IonRouterLink
              className="p-2 pb-0 text-white"
              routerLink="/dashboard/home"
            >
              <IonIcon icon={home} size="large" />
            </IonRouterLink>
          )}

          <IonText className="font-medium text-lg">{task.name}</IonText>
          {index < stage.tasks.length - 1 ? (
            <div className="p-2 pb-0 text-white" onClick={toNextTask}>
              <IonIcon icon={chevronForwardOutline} size="large" />
            </div>
          ) : (
            <button onClick={resume} className="p-2 pb-0 text-white">
              <IonIcon icon={chevronForwardOutline} size="large" />
            </button>
          )}
        </div>
        <div className="h-2 w-full bg-black bg-opacity-10">
          <div
            style={{ width: `${((index + 1) / stage.tasks.length) * 100}%` }}
            className=" h-full bg-button rounded-r-full"
          ></div>
        </div>
      </IonHeader>
      <IonContent fullscreen>
        {isTaskLoading || isStageLoading ? (
          <div className="flex justify-center py-12">
            <IonSpinner />
          </div>
        ) : task.type === "Embed" ? (
          <div className="h-full">
            <RenderTask task={task} />
          </div>
        ) : (
          <div className="container mx-auto max-w-5xl mt-4">
            <RenderTask task={task} />
          </div>
        )}
      </IonContent>
      {showPreLude && (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-black z-10 bg-opacity-90 flex flex-col justify-center items-center px-4">
          <Text className="text-center text-white font-bold text-lg mb-2">
            {stage.prelude_title}
          </Text>
          <Text className="text-center mb-8 text-white">
            {stage.prelude_text}
          </Text>
          <IonButton
            onClick={() => setShowPreLude(false)}
            class="btn-primary"
            type="submit"
          >
            {stage?.prelude_button || t("onboarding.previous-button")}
          </IonButton>
        </div>
      )}
      {showResume && (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-black z-10 bg-opacity-90 flex flex-col justify-center items-center px-4">
          <Text className="text-center text-white font-bold text-lg mb-2">
            {stage.resume_title}
          </Text>
          <Text className="text-center mb-8 text-white">
            {stage.resume_text}
          </Text>

          {stage.position === stage.total_stages &&
          user?.rewards &&
          user?.rewards.length > 0 &&
          !stage.test_id ? (
            <IonButton
              onClick={() => setShowResume(false)}
              class="btn-primary"
              routerLink="/reward"
            >
              {stage?.resume_button || t("onboarding.next-button")}
            </IonButton>
          ) : (
            <IonButton
              onClick={() => setShowResume(false)}
              class="btn-primary"
              routerLink={
                stage.test_id
                  ? `/onboarding/test/${stage.test_id}`
                  : "/dashboard/home"
              }
            >
              {stage?.resume_button || t("onboarding.next-button")}
            </IonButton>
          )}
        </div>
      )}
    </IonPage>
  );
};

export default Task;
