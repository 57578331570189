import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input } from "src/components/Form";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Auth } from "src/api";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonText,
  IonToast,
  IonRouterLink,
} from "@ionic/react";

interface FormData {
  email: string;
}

const ForgotPassword: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<FormData>();
  const { mutateAsync, isError, isSuccess } = useMutation(
    ({ email }: FormData) => Auth.forgotPassword(email)
  );

  const onSubmit = async (data: FormData) => {
    await mutateAsync(data);
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonToast
          isOpen={isError}
          message={t("errors.unknown.message")}
          color="danger"
          ref={(el) => {
            el?.removeAttribute("tabindex");
          }}
          duration={10000}
        />
        <IonToast
          isOpen={isSuccess}
          message={t("login.reset_password_instructions")}
          color="light"
          position="bottom"
          ref={(el) => {
            el?.removeAttribute("tabindex");
          }}
          duration={10000}
        />
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div
          className="h-full bg-cover bg-center"
          style={{
            backgroundImage: `url("/assets/custobo_login_background.jpg")`,
          }}
        >
          <div className="container mx-auto max-w-3xl p-4 h-full flex justify-center items-center">
            <form onSubmit={handleSubmit(onSubmit)} className="mt-48 w-full">
              <div className="space-y-4 mb-4">
                <Input
                  name="email"
                  control={control}
                  placeholder="Email"
                  type="email"
                  rules={{
                    required: true,
                  }}
                  className="bg-white rounded-md"
                />
              </div>
              {/* Ionic submit doesn't work when pressing enter */}
              <button type="submit" className="hidden"></button>
              <IonButton className="btn-login w-full mb-4" type="submit">
                {t("login.reset_password_cta")}
              </IonButton>
              <IonRouterLink
                routerLink="/login"
                className="text-white flex justify-center"
              >
                {t("login.back_to_login")}
              </IonRouterLink>
              <div className="flex justify-center">
                <IonText className="text-center text-white"></IonText>
              </div>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
