import { IonText } from "@ionic/react";
import Showdown from "showdown";
import Vimeo from "@u-wave/react-vimeo";

interface VideoCollectionTaskProps {
  task: OnboardingTask;
}

const converter = new Showdown.Converter({ tables: true });

function VideoCollectionTask({ task }: VideoCollectionTaskProps) {
  return (
    <div className="px-4">
      <IonText
        className="render-html"
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(task.text) }}
      />
      {task.videos &&
        task.videos.map((v) => (
          <div key={v.id} className="mb-8">
            <IonText
              className="render-html"
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(v.description),
              }}
            />
            <Vimeo responsive video={v.vimeo_id ?? ""} />
          </div>
        ))}
    </div>
  );
}

export default VideoCollectionTask;
