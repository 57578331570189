import { useMemo } from "react";
import { IonSelect, IonSelectOption, IonLabel } from "@ionic/react";
import { Control, Controller } from "react-hook-form";
import cn from "classnames";

interface SelectProps {
  question: ProfileQuestion;
  control: Control<any>;
  className?: string;
}

function Select({ question, className, control }: SelectProps) {
  const options = useMemo<string[]>(
    () => question.input_options.split(","),
    [question.input_options]
  );

  return (
    <>
      <IonLabel position="stacked">{question.question}</IonLabel>
      <Controller
        name={question.id.toString()}
        control={control}
        render={({ field: { onChange, value } }) => (
          <IonSelect onIonChange={onChange} value={value}>
            {options.map((o) => (
              <IonSelectOption key={o}>{o}</IonSelectOption>
            ))}
          </IonSelect>
        )}
      />
    </>
  );
}

export default Select;
