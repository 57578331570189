import { authClient } from "src/utils/api";

interface updatePreferencesData {
  notify_email: boolean;
  notify_push: boolean;
}

class Notification {
  static async get(): Promise<NotificationType[]> {
    const res = await authClient().get("/notifications");
    return res.data;
  }

  static async updatePreferences(data: updatePreferencesData): Promise<void> {
    const res = await authClient().post(
      "/session/update_notification_preferences",
      data
    );
    return res.data;
  }
}

export default Notification;
