import {
  IonText,
  IonItem,
  IonImg,
  IonLabel,
  IonRadioGroup,
  IonRadio,
  IonButton,
  IonAlert,
} from "@ionic/react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Showdown from "showdown";
import { useTranslation } from "react-i18next";

interface QuizQuestionCollectionTaskProps {
  task: OnboardingTask;
}

const converter = new Showdown.Converter({ tables: true });

function QuizQuestionCollectionTask({ task }: QuizQuestionCollectionTaskProps) {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ reValidateMode: "onSubmit" });
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation();

  const submit = (data: any) => {
    if (task.quiz_questions && task.quiz_questions.length > 0) {
      // isValid inside useForm does not
      let valid = true;
      Object.entries(data).forEach((o: any) => {
        const question = task.quiz_questions?.find(
          (q) => q.id.toString() === o[0]
        );
        const isCorrect = !!question?.answers.find(
          (a) => a.id === o[1] && a.correct
        );
        if (!isCorrect) {
          setError(o[0], {
            message: t("test.incorrect_answer"),
            type: "wrong",
          });
          valid = false;
        }
      });
      if (valid) {
        setCompleted(true);
      }
    }
  };

  return (
    <div>
      <IonAlert
        isOpen={completed}
        onDidDismiss={() => setCompleted(false)}
        header={t("test.correct_answers")}
        buttons={["Sluiten"]}
      />
      <IonText
        className="render-html"
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(task.text) }}
      />
      <form onSubmit={handleSubmit(submit)}>
        {task.quiz_questions &&
          task.quiz_questions.length > 0 &&
          task.quiz_questions.map((q) => (
            <Controller
              key={q.id}
              name={q.id.toString()}
              control={control}
              rules={{ required: t("form.required") as string }}
              render={({ field: { value, onChange } }) => (
                <IonItem>
                  <div className="pb-8 w-full">
                    <IonRadioGroup
                      value={value}
                      onIonChange={(e) => onChange(e)}
                    >
                      <div className="mb-4">
                        <IonText
                          className="render-html"
                          dangerouslySetInnerHTML={{
                            __html: converter.makeHtml(q.question),
                          }}
                        />
                        {errors && errors[q.id] && (
                          <div className="bg-red-100 px-4 py-2 rounded-md mb-4">
                            <IonText className="text-red-500 block ">
                              {errors[q.id]?.message}
                            </IonText>
                          </div>
                        )}
                        {q.image && <IonImg src={q.image} />}
                      </div>

                      {q.answers.map((a) => (
                        <IonItem key={`${q.id}-${a.id}`}>
                          <IonLabel>{a.answer}</IonLabel>
                          <IonRadio slot="start" value={a.id} />
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  </div>
                </IonItem>
              )}
            />
          ))}
        <IonItem lines="none">
          <div className="mt-8 w-full md:flex md:justify-end">
            <IonButton
              type="submit"
              className="btn-primary w-full md:w-48 h-12 mx-0"
            >
              {t("test.submit")}
            </IonButton>
          </div>
        </IonItem>
      </form>
    </div>
  );
}

export default QuizQuestionCollectionTask;
