import { IonText } from "@ionic/react";
import Showdown from "showdown";

interface TextTaskProps {
  task: OnboardingTask;
}

const converter = new Showdown.Converter({ tables: true });

function TextTask({ task }: TextTaskProps) {
  return (
    <div className="px-4">
      <IonText
        className="render-html"
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(task.text) }}
      />
    </div>
  );
}

export default TextTask;
