interface MessageTaskProps {
  task: OnboardingTask;
}

// TODO
function MessageTask({ task }: MessageTaskProps) {
  return <div className="px-4"></div>;
}

export default MessageTask;
