import { useContext } from "react";
import { AuthContext } from "../../context/auth";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { user, isLoading } = useContext(AuthContext);

  if (isLoading) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return <Redirect to="/login" />;
        }

        if (
          user &&
          user.onboardables[0].profile_questions.filter(
            (q) => q.answer === null
          ).length > 0 &&
          props.location.pathname !== "/welcome" &&
          props.location.pathname !== "/profile-questions"
        ) {
          return <Redirect to="/profile-questions" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};
