import { useForm, UseFormRegisterReturn } from "react-hook-form";
import {
  IonTextarea,
  IonLabel,
  IonItem,
  IonTitle,
  IonButton,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Onboarding } from "src/api";
import cn from "classnames";

interface BioTaskProps {
  task: OnboardingTask;
}

interface BioTaskTextareaProps {
  id: string;
  question: string;
  register: UseFormRegisterReturn;
  minLength?: number;
  error?: boolean;
}

interface FormResult {
  ask_personal: string;
  ask_professional: string;
  ask_specialties: string;
}

function BioTaskTextarea({
  question,
  id,
  register,
  minLength,
  error,
}: BioTaskTextareaProps) {
  const { t } = useTranslation();

  return (
    <IonItem lines="none">
      <div className="w-full ">
        <IonLabel>
          <span className={cn("whitespace-normal", error && "text-red-600")}>
            {question}
          </span>
        </IonLabel>
        <IonTextarea
          className={cn("border rounded w-full", error && "border-red-600")}
          id={id}
          rows={4}
          {...register}
        />
        {minLength && (
          <div className="flex justify-end pt-2">
            <span
              className={cn(
                "text-sm opacity-60",
                error && "text-red-600 opacity-100"
              )}
            >
              {t("form.min_length.many", { count: minLength })}
            </span>
          </div>
        )}
      </div>
    </IonItem>
  );
}

function BioTask({ task }: BioTaskProps) {
  const { t } = useTranslation();
  const { handleSubmit, register } = useForm<FormResult>({
    defaultValues: {
      ask_professional: task.professional_bio,
      ask_personal: task.personal_bio,
      ask_specialties: task.specialties,
    },
  });
  const updateBio = useMutation(
    (bio: {
      personal_bio: string;
      professional_bio: string;
      specialties: string;
    }) => Onboarding.updateBio(bio)
  );

  const submit = (data: FormResult) => {
    try {
      updateBio.mutateAsync({
        personal_bio: data.ask_personal,
        professional_bio: data.ask_professional,
        specialties: data.ask_specialties,
      });
    } catch (err) {
      // TODO: errorhandling
    }
  };

  return (
    <div className="mt-4">
      <IonTitle className="mb-4">{task.name}</IonTitle>
      <form onSubmit={handleSubmit(submit)}>
        <BioTaskTextarea
          id="ask_professional"
          question={task.ask_professional ? task.ask_professional : ""}
          register={register("ask_professional", {
            required: true,
            minLength: 10,
          })}
          minLength={10}
        />
        <BioTaskTextarea
          id="ask_personal"
          question={task.ask_personal ? task.ask_personal : ""}
          register={register("ask_personal", {
            required: true,
            minLength: 10,
          })}
          minLength={10}
        />
        <BioTaskTextarea
          id="ask_specialties"
          question={task.ask_specialties ? task.ask_specialties : ""}
          register={register("ask_specialties", {
            required: true,
            minLength: 10,
          })}
          minLength={10}
        />
        <IonItem lines="none">
          <div className="mt-8 w-full md:flex md:justify-end">
            <IonButton
              type="submit"
              className="btn-primary w-full md:w-48 h-12 mx-0"
            >
              {t("onboarding.save")}
            </IonButton>
          </div>
        </IonItem>
      </form>
    </div>
  );
}

export default BioTask;
