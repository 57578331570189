import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "react-query";
import { Onboarding } from "src/api";
import { IonContent, IonPage, IonButton, IonImg } from "@ionic/react";
import { Text } from "src/components/Ui";
import { useTranslation } from "react-i18next";

const Welcome: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { data } = useQuery("onboardables", () => Onboarding.onboardables());

  function getBackground(): { type: "img" | "video"; uri: string } {
    if (data) {
      const uri = data[0].styles.background_url;
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
      const videoExtensions = ["mp4", "webm", "ogg"];

      if (imageExtensions.includes(uri.split(".").pop() || "")) {
        return {
          type: "img",
          uri,
        };
      }

      if (videoExtensions.includes(uri.split(".").pop() || "")) {
        return {
          type: "video",
          uri,
        };
      }
    }

    return {
      type: "img",
      uri: "https://images.unsplash.com/photo-1543269866-5a654716de64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
    };
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          className="h-full bg-cover"
          style={
            getBackground().type === "img"
              ? {
                  backgroundImage: `url("${getBackground().uri}")`,
                }
              : {}
          }
        >
          {getBackground().type === "video" && (
            <video
              className="h-full w-full object-cover absolute inset-0 z-0"
              autoPlay
              loop
              muted
              playsInline
              src={getBackground().uri}
            />
          )}
          <div className="bg-black bg-opacity-70 h-full z-30 relative">
            <div className="container mx-auto max-w-3xl py-4 h-full flex justify-center items-center">
              {data && data.length > 0 && (
                <div className="w-full px-4 flex flex-col">
                  <div className="flex justify-center mb-8">
                    <div className="flex w-40 h-40 bg-header rounded-full items-center justify-center">
                      <IonImg className="w-24" src={data[0].styles.logo_url} />
                    </div>
                  </div>
                  <div className="mb-8 text-white text-center">
                    <Text>{data[0].welcome_title}</Text>
                    <Text>{data[0].welcome_text}</Text>
                  </div>
                  <IonButton
                    className="btn-primary"
                    onClick={() => history.push("/dashboard/home")}
                  >
                    {t("login.login")}
                  </IonButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
