import { authClient } from "src/utils/api";

class Network {
  static async team(): Promise<any> {
    const res = await authClient().get("/teams");
    return res.data;
  }

  static async contacts(): Promise<Contact[]> {
    const res = await authClient().get("/contacts");
    return res.data;
  }

  static async all(): Promise<any> {
    const res = await authClient().get("/teams/all");
    return res.data;
  }
}

export default Network;
