import { IonHeader, IonIcon, IonTitle, IonImg } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";

interface HeaderProps {
  title?: string;
  logo?: string;
  goBack?: () => void;
}

const Header = ({ title, logo, goBack }: HeaderProps) => {
  if (logo) {
    return (
      <IonHeader className="bg-header safe-area-top min-h-[56px] flex items-center justify-center">
        <IonImg className="h-12 py-2" src={logo} />
      </IonHeader>
    );
  }

  if (goBack) {
    return (
      <IonHeader className="bg-header safe-area-top grid grid-cols-6 min-h-[56px]">
        <div className="p-2 text-white flex items-center" onClick={goBack}>
          <IonIcon icon={chevronBackOutline} size="large" />
        </div>
        <IonTitle className="ion-text-center col-span-4 text-white">
          {title}
        </IonTitle>
      </IonHeader>
    );
  }

  if (title) {
    return (
      <IonHeader className="bg-header safe-area-top min-h-[56px] flex items-center justify-center">
        <IonTitle className="ion-text-center py-2 text-white">{title}</IonTitle>
      </IonHeader>
    );
  }
  return null;
};

export default Header;
