import axios from "axios";

const url = "https://admin.custobo.com/api/v1";

export const client = axios.create({
  baseURL: url,
  headers: {
    Accept: "application/json; charset=utf-8",
    "Cache-Control": "no-cache",
    "X-Key-Inflection": "camel",
  },
  timeout: 10000,
});

// The authClient needs to be an exacutable to retrieve the session
// from localstorage
export const authClient = () =>
  axios.create({
    baseURL: url,
    headers: {
      Accept: "application/json; charset=utf-8",
      "Cache-Control": "no-cache",
      "X-Key-Inflection": "camel",
      Authorization: `Bearer ${localStorage.getItem("sess")}`,
    },
    timeout: 10000,
  });
