import { IonInput, IonLabel } from "@ionic/react";
import { Controller, Control } from "react-hook-form";

interface InputProps {
  control: Control<any>;
  question: ProfileQuestion;
  className?: string;
}

function Input({ control, question, className }: InputProps) {
  return (
    <div className={className}>
      <Controller
        control={control}
        name={question.id.toString()}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <>
              <IonLabel>{question.question}</IonLabel>
              <IonInput
                className="border bg-white text-gray-150 rounded-md mt-1"
                onIonChange={onChange}
                value={value}
                ref={ref}
              />
            </>
          );
        }}
      />
    </div>
  );
}

export default Input;
