import { useEffect } from "react";
import { IonItem, IonCheckbox, IonLabel } from "@ionic/react";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import Onboarding from "src/api/onboarding";
import { Text } from "src/components/Ui";

interface SubjectCollectionTaskProps {
  task: OnboardingTask;
}

function SubjectCollectionTask({ task }: SubjectCollectionTaskProps) {
  const { control } = useForm();
  const client = useQueryClient();
  const select = useMutation(
    ({ subjectId, selected }: { subjectId: string; selected: boolean }) => {
      return (async () => {
        console.log(subjectId, selected);
      })();
      // return Onboarding.submitSelectedSubject({ subjectId, selected });
    }
  );

  const update = async (id: number, selected: boolean) => {
    try {
      await select.mutateAsync({
        subjectId: id.toString(),
        selected,
      });
      // client.invalidateQueries(["task", task.id]);
    } catch (err) {
      // TODO: error handling
    }
  };

  if (!task) return null;

  return (
    <div className="pb-8">
      <div className="px-4">
        <Text className="render-html">{task.text}</Text>
      </div>
      {task.subjects?.map((s) => (
        <Controller
          key={s.id}
          name={s.subject}
          control={control}
          defaultValue={s.selected}
          render={({ field: { value, onChange } }) => {
            return (
              <IonItem>
                <IonLabel>{s.subject}</IonLabel>
                <IonCheckbox
                  checked={value}
                  onIonChange={(e) => {
                    update(s.id, e.detail.checked);
                    onChange(e.detail.checked);
                  }}
                />
              </IonItem>
            );
          }}
        />
      ))}
    </div>
  );
}

export default SubjectCollectionTask;
