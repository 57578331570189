import { IonText, IonIcon, IonRouterLink } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import Showdown from "showdown";

interface TeamPageTaskProps {
  task: OnboardingTask;
}

const converter = new Showdown.Converter({ tables: true });

function TeamPageTask({ task }: TeamPageTaskProps) {
  const { t } = useTranslation();

  return (
    <div>
      <IonText
        className="render-html"
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(task.text) }}
      />
      <IonRouterLink routerLink="/network/team">
        <div className="flex justify-between border-t border-b p-4 items-center text-black">
          <span>{t("team.my_team")}</span>
          <IonIcon icon={chevronForwardOutline} />
        </div>
      </IonRouterLink>
      <IonRouterLink routerLink="/network/contacts">
        <div className="flex justify-between border-b p-4 items-center text-black">
          <span>{t("team.contacts")}</span>
          <IonIcon icon={chevronForwardOutline} />
        </div>
      </IonRouterLink>
      <IonRouterLink routerLink="/network/other">
        <div className="flex justify-between border-b p-4 items-center text-black">
          <span>{t("team.other")}</span>
          <IonIcon icon={chevronForwardOutline} />
        </div>
      </IonRouterLink>
    </div>
  );
}

export default TeamPageTask;
