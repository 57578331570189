import { useContext, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "react-query";
import { AuthContext } from "src/context/auth";
import { Onboarding as OnboardingApi, TipsAndTricks } from "src/api";
import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import { Header } from "src/components/Ui";

import { useTranslation } from "react-i18next";
import cn from "classnames";

import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

const Onboarding: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { data: tipsAndTricks } = useQuery(["tipsAndTricks"], () =>
    TipsAndTricks.get()
  );
  const { data: dashboardItems } = useQuery(["dashboard"], () =>
    OnboardingApi.dashboard()
  );
  const items = useMemo((): DashboardItem[] => {
    if (user && dashboardItems) {
      return dashboardItems
        .filter(
          (i) =>
            i.department_id === user.onboardables[0].id ||
            i.department_id === null
        )
        .sort((a, b) => a.position - b.position);
    }
    return [];
  }, [user, dashboardItems]);

  return (
    <IonPage>
      <Header logo={user?.styles.logo_url}></Header>
      <IonContent fullscreen>
        {tipsAndTricks && tipsAndTricks.length > 0 && (
          <div className="p-4 bg-gray-100 flex flex-row space-x-4 items-center justify-start overflow-x-scroll">
            {tipsAndTricks.map((item) => {
              return (
                <div
                  key={item.id}
                  className="relative flex flex-col h-full min-w-[300px] items-end rounded-xl shadow-lg"
                >
                  <div className="border bg-white rounded-xl w-full h-1/2">
                    <img
                      className="h-32 w-full rounded-t-xl object-cover  "
                      src={item.image}
                      alt="Custobo stage"
                    />
                  </div>

                  <div className="px-4 py-4 border-t -mt-2 bg-white rounded-b-xl flex flex-col items-stretch w-full">
                    <div>
                      <div className="font-bold text-base mb-2">
                        {item.name}
                      </div>
                    </div>

                    <IonRouterLink
                      href={`/onboarding/task/${item.tasks[0].id}`}
                      className="btn-primary h-10 text-center rounded leading-10 font-medium"
                    >
                      {t("stage.view")}
                    </IonRouterLink>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="container  mx-auto max-w-3xl px-4 py-8 flex flex-col bg-white ">
          {items.map((item, i) => (
            <IonRouterLink
              href={item.link ? `${item.link}?dashboard=true` : undefined}
              key={item.id}
              className="block relative mb-4 rounded-xl overflow-hidden "
            >
              <div
                className={cn(
                  "w-full shadow-lg h-[192px] flex flex-col justify-end"
                )}
                style={{ background: item.background_color || "none" }}
              >
                {item.icon && (
                  <div className=" absolute z-20 top-4 left-4 h-12 w-12 flex justify-center items-center">
                    <img
                      src={`/assets/icons/${item.icon}.svg`}
                      alt="icon"
                      className="h-full"
                    />
                  </div>
                )}
                {item.background_image ? (
                  <img
                    className="h-full  left-0 top-0 w-full object-cover"
                    src={item.background_image}
                    alt=""
                  />
                ) : (
                  <img
                    className="h-full absolute left-0 top-0 w-full object-cover"
                    src={
                      item.id % 2
                        ? "/assets/frame01.png"
                        : "/assets/frame02.png"
                    }
                    alt=""
                  />
                )}
                {item.name && (
                  <div className="flex justify-between items-end w-full absolute z-50 p-4">
                    <h3 className="z-50 m-0 text-white text-xl font-semibold">
                      {item.name}
                    </h3>
                  </div>
                )}
              </div>
              <div className="absolute bottom-0 left-0 w-full h-2/3 pointer-events-none bg-gradient-to-t from-black/40"></div>
            </IonRouterLink>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Onboarding;
