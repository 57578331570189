import { IonContent, IonPage, IonSpinner, IonImg } from "@ionic/react";
import { Notification } from "src/api";
import { useQuery } from "react-query";
import { Header } from "src/components/Ui";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// TODO: Check types from notifications

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const { data, isError, isLoading } = useQuery(["notifications"], () =>
    Notification.get()
  );

  return (
    <IonPage>
      <Header title={t("notification.title")}></Header>
      <IonContent>
        {isLoading ? (
          <div className="flex justify-center py-12">
            <IonSpinner />
          </div>
        ) : (
          <div className="container mx-auto max-w-3xl p-4 flex flex-col h-full">
            {isError && (
              <div className="w-full text-center flex flex-col justify-center items-center h-full">
                <h1>{t("errors.unknown.title")}</h1>
                <span>{t("errors.unknown.message")}</span>
              </div>
            )}
            <div className="space-y-4">
              {data &&
                !isError &&
                data.map((n) => {
                  return n.title || n.image || n.message ? (
                    <a
                      key={n.id}
                      className="space-y-2 border-b pb-4 border-black last:border-0 text-black block"
                      href={n.link}
                    >
                      <h4 className="mt-0">{n.title}</h4>
                      {n.image && <IonImg className="w-full" src={n.image} />}
                      <p>{n.message}</p>
                      {n.title ||
                        n.image ||
                        (n.message && (
                          <div className="text-sm opacity-70 text-right">
                            {dayjs(n.date).format("DD-MM-YYYY")}
                          </div>
                        ))}
                    </a>
                  ) : null;
                })}
            </div>
            {data && data.length === 0 && (
              <div className="w-full text-center flex flex-col justify-center items-center h-full">
                <span>{t("notification.no_notifications")}</span>
              </div>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
