import { useTranslation } from "react-i18next";
import { IonButton, IonItem, IonText } from "@ionic/react";
import Showdown from "showdown";

interface ContradictionsSentenceTaskProps {
  task: OnboardingTask;
}

const converter = new Showdown.Converter({ tables: true });

function ContradictionsSentenceTask({ task }: ContradictionsSentenceTaskProps) {
  const { t } = useTranslation();

  if (!task) return null;

  return (
    <div>
      <IonItem lines="none">
        <IonText
          className="render-html"
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(task.name) }}
        />
      </IonItem>
      <IonItem lines="none">
        <IonText
          className="render-html"
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(task.sentence as string),
          }}
        />
      </IonItem>
      <IonItem lines="none">
        <div className="w-full md:flex md:justify-end">
          <IonButton
            type="submit"
            className="btn-primary w-full md:w-48 h-12 mx-0"
          >
            {t("test.submit")}
          </IonButton>
        </div>
      </IonItem>
    </div>
  );
}

export default ContradictionsSentenceTask;
