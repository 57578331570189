import { IonText } from "@ionic/react";
import Showdown from "showdown";

interface TimelineTaskProps {
  task: OnboardingTask;
}

const converter = new Showdown.Converter({ tables: true });

function TimelineTask({ task }: TimelineTaskProps) {
  return (
    <div>
      <div className="px-4">
        <IonText
          className="render-html"
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(task.text) }}
        />
      </div>
      <div className="px-4">
        {task.timeline_events && task.timeline_events.length > 0 && (
          <>
            <div className="w-6 h-0.5 bg-black"></div>
            <div className="ml-3 py-6 border-l-2 border-black flex flex-col space-y-8">
              {task.timeline_events.map((t) => (
                <div className="flex items-center" key={t.id}>
                  <div className="flex-none w-6 h-0.5 bg-black"></div>
                  <div className="flex-grow">
                    <div className="border-2 px-4 py-2 rounded-md">
                      <div className="text-lg font-bold">{t.name}</div>
                      <div className="">{t.text}</div>
                    </div>
                    {t.quote && (
                      <div className="pl-4 mt-2 text-lg italic">
                        "{t.quote}"
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="w-6 h-0.5 bg-black"></div>
          </>
        )}
      </div>
    </div>
  );
}

export default TimelineTask;
