import { Redirect, Route } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "src/context/auth";
import {
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { home, person, settingsSharp, notifications } from "ionicons/icons";
import HomeTab from "src/pages/Home";
import ProfileTab from "src/pages/Profile";
import NotificationsTab from "src/pages/Notifications";
import SettingsTab from "src/pages/Settings";
import PostOnboarding from "src/pages/PostOnboarding";

const Dashboard: React.FC = () => {
  const { user } = useContext(AuthContext);

  return (
    <IonTabs>
      <IonRouterOutlet animated={false}>
        <Redirect exact path="/dashboard" to="/dashboard/home" />
        <Route
          exact
          path="/dashboard/home"
          render={(props) =>
            user?.onboarding_completed ? (
              <PostOnboarding {...props} />
            ) : (
              <HomeTab />
            )
          }
        />
        <Route exact path="/dashboard/profile" component={ProfileTab} />
        <Route
          exact
          path="/dashboard/notifications"
          component={NotificationsTab}
        />
        <Route exact path="/dashboard/settings" component={SettingsTab} />
      </IonRouterOutlet>
      <IonTabBar className="bg-header shadow-xl" id="appTabBar" slot="bottom">
        <IonTabButton tab="home" href="/dashboard/home" className="bg-header">
          <IonIcon className="w-8 h-8" icon={home} />
        </IonTabButton>
        <IonTabButton
          tab="profile"
          href="/dashboard/profile"
          className="bg-header"
        >
          <IonIcon className="w-8 h-8" icon={person} />
        </IonTabButton>
        <IonTabButton
          tab="notifications"
          href="/dashboard/notifications"
          className="bg-header"
        >
          <IonIcon className="w-8 h-8" icon={notifications} />
        </IonTabButton>
        <IonTabButton
          tab="settings"
          href="/dashboard/settings"
          className="bg-header"
        >
          <IonIcon className="w-8 h-8" icon={settingsSharp} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Dashboard;
