import { client } from "src/utils/api";

interface CheckListProps {
  id: number;
  name: string;
  description: string;
  created_at: string;
  items: {
    id: number;
    text: string;
  }[];
}

class Checklist {
  static async checklist(): Promise<CheckListProps> {
    const res = await client("/checklist", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sess")}`,
      },
    });

    if (res.data && Object.keys(res.data).length === 0) {
      throw new Error("Not found");
    }

    return res.data;
  }

  static async complete(): Promise<CheckListProps> {
    const res = await client("/session/submit_checklist", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sess")}`,
      },
    });
    return res.data;
  }
}

export default Checklist;
