import type { RouteComponentProps } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { Onboarding, Stages, Auth } from "src/api";
import { Header, Text } from "src/components/Ui";
import { Controller, useForm } from "react-hook-form";
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonImg,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItem,
  IonButton,
} from "@ionic/react";
import { AuthContext } from "src/context/auth";

interface Params {
  id?: string;
}

const Test: React.FC<RouteComponentProps<Params>> = ({ match, history }) => {
  const { revalidateSession } = useContext(AuthContext);
  const { t } = useTranslation();
  const me = useMutation(() => Auth.me());
  const complete = useMutation(() =>
    Onboarding.completeTest(match.params.id as string)
  );
  const [completed, setCompleted] = useState(false);
  const [hasReward, setHasReward] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const { data, isLoading } = useQuery(
    ["/test", match.params.id],
    () => Onboarding.test(match.params.id),
    { enabled: !!match.params.id }
  );

  const { data: stage } = useQuery(
    ["stage", data?.stage_id],
    () => Stages.stage(data?.stage_id as number),
    { enabled: !!data }
  );

  const completeTest = async () => {
    try {
      await complete.mutateAsync();
      if (stage && stage.position === stage.total_stages) {
        const updatedUser = await me.mutateAsync();
        if (updatedUser.rewards.length > 0) {
          setHasReward(true);
          revalidateSession();
        }
      }
      setCompleted(true);
    } catch (err) {
      // TODO: error handling
    }
  };

  const submit = async (d: any) => {
    if (data && data.questions.length > 0) {
      // isValid inside useForm does not
      let valid = true;
      Object.entries(d).forEach((o: any) => {
        const question = data.questions?.find((q) => q.id.toString() === o[0]);
        const isCorrect = !!question?.answers.find(
          (a) => a.id === o[1] && a.correct
        );
        if (!isCorrect) {
          setError(o[0], {
            message: t("test.incorrect_answer"),
            type: "wrong",
          });
          valid = false;
        }
      });
      if (valid) {
        completeTest();
      }
    }
  };

  return (
    <IonPage>
      <Header title={t("test.title")} goBack={() => history.goBack()} />
      <IonContent>
        {data && !isLoading ? (
          <form className="space-y-4 py-4" onSubmit={handleSubmit(submit)}>
            <div className="border-b pb-4 px-4">
              <Text className="text-center">{data.intro}</Text>
            </div>
            {data.questions.map((q) => (
              <Controller
                key={q.id}
                name={q.id.toString()}
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <div key={q.id} className="space-y-4 border-b pb-4">
                    <div className="px-4">
                      <Text>{q.question}</Text>
                    </div>
                    {q.image && <IonImg className="px-4" src={q.image} />}
                    <div>
                      {errors && errors[q.id] && (
                        <div className="bg-red-100 px-4 py-2 rounded-md mb-4">
                          <Text className="text-red-500 block ">
                            {/* @ts-ignore */}
                            {errors[q.id]?.message}
                          </Text>
                        </div>
                      )}
                      <IonRadioGroup
                        value={value}
                        onIonChange={(e) => onChange(e)}
                      >
                        {q.answers.map((a) => (
                          <IonItem lines="none" key={`${q.id}-${a.id}`}>
                            <IonLabel style={{ whiteSpace: "normal" }}>
                              {a.answer}
                            </IonLabel>
                            <IonRadio slot="start" value={a.id} />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </div>
                  </div>
                )}
              />
            ))}
            <div className="px-4 save-area-bottom">
              <IonButton
                type="submit"
                className="btn-primary w-full md:w-48 h-12"
              >
                {t("test.submit")}
              </IonButton>
            </div>
          </form>
        ) : (
          <div className="flex justify-center py-12">
            <IonSpinner />
          </div>
        )}
      </IonContent>
      {completed && (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-black z-10 bg-opacity-90 flex flex-col justify-center items-center px-4">
          <Text className="text-center text-white font-bold text-lg mb-2">
            {t("test.correct_answers_title")}
          </Text>
          <Text className="text-center mb-8 text-white">
            {t("test.correct_answers")}
          </Text>

          <IonButton
            onClick={() => setCompleted(false)}
            class="btn-primary"
            routerLink={hasReward ? "/reward" : "/dashboard/home"}
          >
            {t("test.correct_answers_button")}
          </IonButton>
        </div>
      )}
    </IonPage>
  );
};

export default Test;
