import React, { useContext, useState, useMemo, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonButton,
  IonAvatar,
  IonTitle,
  IonImg,
  IonItem,
  IonRouterLink,
  IonIcon,
  IonSpinner,
  IonToast,
  IonItemGroup,
  IonCheckbox,
} from "@ionic/react";
import { Header } from "src/components/Ui";
import { Input, TextAreaP } from "src/components/Form";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "src/context/auth";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Profile as ProfileApi, Checklist } from "src/api";
import { chevronForward, checkbox } from "ionicons/icons";
import { Checkboxes, Select } from "src/components/ProfileForm";

interface FormData {
  [key: string]: any;
  email: string;
  telephone: string;
  date_of_birth: string;
  job_title: string;
  linked_in_url: string;
  personal_bio: string;
  professional_bio: string;
  specialties: string;
}

const Profile: React.FC = () => {
  const client = useQueryClient();
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const {
    user,
    isLoading: isUserLoading,
    revalidateSession,
  } = useContext(AuthContext);
  const [interests, setInterests] = useState<UserInterestsItem[]>(
    user?.interests || []
  );
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const { mutateAsync: updateInterests } = useMutation(
    ProfileApi.updateInterests
  );
  const { mutateAsync: updateBio, isLoading: isLoadingPicture } = useMutation(
    (data: FormData) => ProfileApi.updateBio(data)
  );
  const updateProfileAnswers = useMutation((list: any) =>
    ProfileApi.updateMany(list)
  );
  const { mutateAsync: updateProfilePicture, isLoading: isSavingPicture } =
    useMutation(({ file }: { file: File }) => ProfileApi.updatePicture(file));
  const { data: picture, isLoading: isPictureLoading } = useQuery(
    ["/session/profile_picture"],
    () => ProfileApi.picture()
  );
  const profileQuestions = useMemo(() => {
    if (user && user.onboardables[0].profile_questions) {
      let questions: any = {};

      user.onboardables[0].profile_questions.forEach((q) => {
        if (q.input_type === "checkboxes") {
          // Array gets return as a string from the api so we have to parse it
          questions[q.id.toString()] = JSON.parse(q.answer as string);
        } else {
          questions[q.id.toString()] = q.answer;
        }
      });

      return questions;
    }

    return {};
  }, [user]);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: user?.email,
      telephone: user?.telephone,
      date_of_birth: user?.date_of_birth,
      job_title: user?.job_title,
      personal_bio: user?.personal_bio,
      professional_bio: user?.professional_bio,
      specialties: user?.specialties,
      linked_in_url: user?.linked_in_url,
      ...profileQuestions,
    },
  });
  const { data: checklist } = useQuery(
    ["checklist"],
    () => Checklist.checklist(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

  useEffect(() => {
    (async () => {
      if (profilePicture) {
        await updateProfilePicture({ file: profilePicture });
        client.invalidateQueries("/session/profile_picture");
      }
    })();
  }, [profilePicture]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsSaving(true);

    // profile questions need to be send to a different endpoint
    if (profileQuestions && user) {
      const answers = user.onboardables[0].profile_questions.map((q) => {
        const temp = [q.id.toString(), data[q.id.toString()]];
        delete data[q.id.toString()];
        return temp;
      });

      await updateProfileAnswers.mutateAsync(answers);
    }

    if (interests && interests.length > 0) {
      await updateInterests(interests);
    }
    await updateBio(data);
    revalidateSession();
  };

  return (
    <IonPage>
      <IonToast
        isOpen={isSaving}
        onDidDismiss={() => setIsSaving(false)}
        message={t("bio.saving")}
        duration={2000}
      />
      <Header title={t("profile.title")}></Header>
      <IonContent fullscreen>
        {!user || isUserLoading || isPictureLoading ? (
          <div className="flex justify-center py-12">
            <IonSpinner />
          </div>
        ) : (
          <div className="container mx-auto max-w-5xl ">
            <div className="flex flex-col items-center justify-center my-8">
              <IonAvatar className="w-32 md:w-40 h-32 md:h-40 relative mb-2 overflow-hidden">
                {picture && picture.profile_picture ? (
                  <IonImg
                    src={`data:image/jpg;base64,${picture.profile_picture}`}
                  />
                ) : (
                  <IonImg src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                )}
                {(isSavingPicture || isLoadingPicture) && (
                  <div className="bg-black absolute inset-0 bg-opacity-70 flex justify-center items-center">
                    <IonSpinner color="light" />
                  </div>
                )}
              </IonAvatar>
              <IonTitle>
                {user?.first_name} {user?.last_name}
              </IonTitle>
              <span className="opacity-50">{user?.job_title}</span>
            </div>
            {user.rewards && user.rewards.length > 0 && (
              <IonItem lines="full" className="bg-neutral-100">
                <IonLabel position="stacked">{t("bio.rewards")}</IonLabel>
                <ul className="py-4 space-y-4">
                  {user.rewards.map((r) => (
                    <li key={r.id} className="flex items-center space-x-2">
                      <img
                        src={`/assets/icons/${r.icon || "carriere"}.svg`}
                        alt="icon"
                        className="h-6"
                      />
                      <div className="font-medium">{r.title}</div>
                    </li>
                  ))}
                </ul>
              </IonItem>
            )}
            {!user.onboardables[0].hidden_profile_fields.includes("avatar") && (
              <IonItem lines="full">
                <IonLabel position="stacked">
                  {t("bio.profile_picture")}
                </IonLabel>
                <input
                  className="pt-4 pb-3"
                  accept="image/jpg" // Backend currently only supports jpg uploads
                  type="file"
                  multiple={false}
                  onChange={(e) => {
                    if (e.target.files?.item(0)) {
                      setProfilePicture(e.target.files?.item(0));
                      e.target.value = "";
                    }
                  }}
                />
              </IonItem>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              {checklist && (
                <IonItem lines="full">
                  <IonLabel position="stacked">{t("bio.checklists")}</IonLabel>
                  <IonRouterLink
                    routerLink="/checklist"
                    className="w-full mt-2 py-2"
                  >
                    <div className="flex justify-between w-full items-center text-black font-medium ">
                      <div className="flex space-x-2 items-center">
                        <IonIcon icon={checkbox} />
                        <span>{t("onboarding.checklist")}</span>
                      </div>
                      <IonIcon icon={chevronForward} />
                    </div>
                  </IonRouterLink>
                </IonItem>
              )}
              {!user.onboardables[0].hidden_profile_fields.includes(
                "email"
              ) && (
                <IonItem>
                  <Input
                    label={t("network.mail")}
                    control={control}
                    name="email"
                    type="email"
                    error={errors.email?.message}
                    rules={{
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("login.invalid_email"),
                      },
                    }}
                  />
                </IonItem>
              )}
              {!user.onboardables[0].hidden_profile_fields.includes(
                "phone"
              ) && (
                <IonItem>
                  <Input
                    label={t("network.phone")}
                    control={control}
                    name="telephone"
                    type="tel"
                  />
                </IonItem>
              )}
              {!user.onboardables[0].hidden_profile_fields.includes(
                "birthday"
              ) && (
                <IonItem>
                  <Input
                    label={t("network.birthdate")}
                    control={control}
                    name="date_of_birth"
                    type="date"
                  />
                </IonItem>
              )}
              {!user.onboardables[0].hidden_profile_fields.includes(
                "title"
              ) && (
                <IonItem>
                  <Input
                    label={t("network.job_title")}
                    control={control}
                    name="job_title"
                  />
                </IonItem>
              )}
              {!user.onboardables[0].hidden_profile_fields.includes(
                "linkedin"
              ) && (
                <IonItem>
                  <Input
                    label={t("network.linkedin")}
                    control={control}
                    name="linked_in_url"
                  />
                </IonItem>
              )}
              <IonItem>
                <TextAreaP
                  label={t("network.personal_bio")}
                  control={control}
                  name="personal_bio"
                />
              </IonItem>
              <IonItem>
                <TextAreaP
                  label={t("network.professional_bio")}
                  control={control}
                  name="professional_bio"
                />
              </IonItem>
              <IonItem>
                <TextAreaP
                  label={t("network.specialties")}
                  control={control}
                  name="specialties"
                />
              </IonItem>
              {interests && interests.length > 0 && (
                <IonItemGroup className="ion-no-padding">
                  <IonLabel position="stacked" className="pl-5 pb-4">
                    {t("network.interests")}
                  </IonLabel>
                  {interests.map((t, i) => (
                    <IonItem key={t.id} lines="none">
                      <IonCheckbox
                        slot="start"
                        value={interests[i]}
                        checked={interests[i].active}
                        onIonChange={({ detail: { checked } }) => {
                          const temp = [...interests];
                          temp[i].active = checked ? true : false;
                          setInterests(temp);
                        }}
                      />
                      <IonLabel>{t.name}</IonLabel>
                    </IonItem>
                  ))}
                </IonItemGroup>
              )}
              {user.onboardables[0].profile_questions.map((q) => (
                <React.Fragment key={q.id}>
                  {q.input_type === "checkboxes" && (
                    <IonItem className="ion-no-padding">
                      <Checkboxes control={control} question={q} />
                    </IonItem>
                  )}
                  {q.input_type === "select" && (
                    <IonItem key={q.id}>
                      <Select control={control} question={q} />
                    </IonItem>
                  )}
                  {q.input_type === "text" && (
                    <IonItem key={q.id}>
                      <Input
                        name={q.id.toString()}
                        label={q.question}
                        control={control}
                      />
                    </IonItem>
                  )}
                </React.Fragment>
              ))}

              <div className="my-4 flex justify-end px-4">
                <IonButton class="btn-primary w-full md:w-auto" type="submit">
                  {t("profile.button_text")}
                </IonButton>
              </div>
            </form>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Profile;
