import { Text } from "src/components/Ui";
import Vimeo from "@u-wave/react-vimeo";

interface VideoTaskProps {
  task: OnboardingTask;
}

function VideoTask({ task }: VideoTaskProps) {
  return (
    <div className="px-4">
      <Text className="render-html">{task.text}</Text>
      <Vimeo responsive video={task.video_id ?? ""} />
    </div>
  );
}

export default VideoTask;
