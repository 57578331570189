import { useForm, Controller } from "react-hook-form";
import {
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonItem,
  IonButton,
  IonTextarea,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import Onboarding from "src/api/onboarding";
import cn from "classnames";
import { useMutation } from "react-query";

interface AskAQuestionTaskProps {
  task: OnboardingTask;
}

function AskAQuestionTask({ task }: AskAQuestionTaskProps) {
  const { handleSubmit, control, register } = useForm();
  const { t } = useTranslation();
  const submitSubject = useMutation(
    ({ subjectId, question }: { subjectId: string; question: string }) =>
      Onboarding.submitSubject({ subjectId, question })
  );

  const submit = async (data: any) => {
    try {
      await submitSubject.mutateAsync({
        subjectId: data.subject.toString(),
        question: data.question,
      });
    } catch (err) {}
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <IonItem lines="none">
          <IonLabel>Onderwerp:</IonLabel>
          <Controller
            name={"subject"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <IonSelect onIonChange={onChange} value={value}>
                {task.subjects &&
                  task.subjects.map((s) => (
                    <IonSelectOption key={s.id} value={s.id}>
                      {s.subject}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            )}
          />
        </IonItem>
        <IonItem lines="none">
          <div className="w-full">
            <IonLabel>Omschrijf hier je antwoord.</IonLabel>
            <IonTextarea
              className={cn("border rounded w-full")}
              rows={4}
              {...register("question", { required: true })}
            />
          </div>
        </IonItem>
        <IonItem lines="none">
          <div className="mt-8 w-full md:flex md:justify-end">
            <IonButton
              type="submit"
              className="btn-primary w-full md:w-48 h-12 mx-0"
            >
              {t("onboarding.save")}
            </IonButton>
          </div>
        </IonItem>
      </form>
    </div>
  );
}

export default AskAQuestionTask;
