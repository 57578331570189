import { IonText, IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import Showdown from "showdown";

interface LinkCollectionTaskProps {
  task: OnboardingTaskLinkCollection;
}

const converter = new Showdown.Converter({ tables: true });

function LinkCollectionTask({ task }: LinkCollectionTaskProps) {
  return (
    <div>
      <div className="px-4">
        <IonText
          className="render-html"
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(task.link_text),
          }}
        />
      </div>
      {task.links.map((t) => (
        <div key={t.id}>
          <a
            className="flex justify-between border-t border-b p-4 items-center text-black"
            href={t.url}
          >
            <span>{t.name}</span>
            <IonIcon icon={chevronForwardOutline} />
          </a>
        </div>
      ))}
    </div>
  );
}

export default LinkCollectionTask;
