import { RouteComponentProps } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "src/context/auth";
import {
  IonContent,
  IonPage,
  IonItem,
  IonButton,
  IonToast,
  IonText,
} from "@ionic/react";
import { Header } from "src/components/Ui";
import { CheckboxP } from "src/components/Form";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Notification } from "src/api";

interface FormData {
  notify_email: boolean;
  notify_push: boolean;
}

const NotificationSettings: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);
  const { mutateAsync, isLoading } = useMutation((data: FormData) =>
    Notification.updatePreferences(data)
  );
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      notify_email: user?.notification_preferences.notify_email,
      notify_push: user?.notification_preferences.notify_push,
    },
  });

  const submit = async (data: FormData) => {
    setIsSaving(true);
    await mutateAsync(data);
  };

  return (
    <IonPage>
      <IonToast
        isOpen={isSaving}
        onDidDismiss={() => setIsSaving(false)}
        message={t("settings.notification_preferences_updating")}
        duration={2000}
      />
      <Header
        title={t("settings.notification_preferences")}
        goBack={() => history.goBack()}
      ></Header>
      <IonContent fullscreen>
        <div className="container mx-auto max-w-5xl">
          <IonItem lines="none" className="my-4">
            <IonText>
              {t("settings.notification_preferences_description")}
            </IonText>
          </IonItem>
          <form onSubmit={handleSubmit(submit)}>
            <IonItem lines="full">
              {/* Not translation */}
              <CheckboxP
                control={control}
                name="notify_push"
                label="Push notification"
              />
            </IonItem>
            <IonItem lines="full">
              {/* Not translation */}
              <CheckboxP control={control} name="notify_email" label="Email" />
            </IonItem>
            <div className="mx-4 mt-4">
              <IonButton
                disabled={isLoading}
                className="btn-primary"
                style={{ width: "100%" }}
                type="submit"
              >
                {t("settings.notification_preferences_update")}
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotificationSettings;
