import type { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IonContent, IonPage, IonText, IonHeader, IonIcon } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import Network from "src/api/network";
import { useQuery } from "react-query";

const Team: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { data: team } = useQuery(["team"], () => Network.team());

  if (!team) return null;

  return (
    <IonPage>
      <IonHeader className="bg-header text-white shadow-none flex items-center justify-between ">
        <div className="p-2 pb-0 text-white" onClick={() => history.goBack()}>
          <IonIcon icon={chevronBackOutline} size="large" />
        </div>
        <IonText className="font-medium text-lg">{t("team.my_team")}</IonText>
        <div className="w-8"></div>
      </IonHeader>
      <IonContent fullscreen>
        {team.length > 0 ? (
          <div></div>
        ) : (
          <div className="p-4 flex justify-center">
            <IonText>{t("network.no_team_members")}</IonText>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Team;
