import { client } from "src/utils/api";
import { AxiosResponse } from "axios";

class Auth {
  /**
   * GET: User session
   */
  static async me(): Promise<User> {
    const res = await client("/session", {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("sess")}` },
    });
    return res.data;
  }

  /**
   * POST: User login request
   * @param {String} username
   * @param {String} password
   */
  static async login(
    username: string,
    password: string
  ): Promise<AxiosResponse<User>> {
    return await client.post("/session", { username, password });
  }

  /**
   * POST: Forgot password
   * @param {String} email
   */
  static async forgotPassword(email: string) {
    const res = await client.post("/session/forgot_password", {
      email,
    });

    return res.data;
  }
}

export default Auth;
