import { useContext } from "react";
import { Header, Text } from "src/components/Ui";
import { AuthContext } from "src/context/auth";
import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Profile } from "src/api";

function Rewards() {
  const { data } = useQuery(["rewards"], () => Profile.rewards());
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  return (
    <IonPage>
      <Header logo={user?.styles.logo_url}></Header>
      <IonContent fullscreen>
        <div className="mx-auto max-w-3xl px-4 pt-6">
          {data && data[0] && (
            <>
              {data[0].image && (
                <img
                  className="w-full h-full mb-8"
                  src={data[0].image}
                  alt={data[0].title}
                />
              )}
              {data[0].title && (
                <h1 className="text-center text-2xl font-bold mt-0 mb-2">
                  {data[0].title}
                </h1>
              )}
              {data[0].description && (
                <Text className="text-center">{data[0].description}</Text>
              )}
            </>
          )}
          <IonRouterLink
            class="btn-primary h-10 text-center rounded leading-10 font-medium block mt-8"
            routerLink="/dashboard/home"
          >
            {t("errors.go_to_dashboard")}
          </IonRouterLink>
          <Confetti recycle={false} className="w-full h-full" />
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Rewards;
