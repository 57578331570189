import { useContext, useEffect, useState } from "react";
import { Header } from "src/components/Ui";
import { AuthContext } from "src/context/auth";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Onboarding, Checklist } from "src/api";
import {
  IonContent,
  IonPage,
  IonItem,
  IonSpinner,
  IonRouterLink,
  IonText,
  IonIcon,
} from "@ionic/react";
import { lockClosed, clipboard, checkmark } from "ionicons/icons";
import cn from "classnames";

interface OnboardingItem extends Stage {
  isLocked: boolean;
}

const Home: React.FC = () => {
  const { user, revalidateSession } = useContext(AuthContext);
  const { t } = useTranslation();
  const [stages, setStages] = useState<OnboardingItem[]>([]);
  const { data, isLoading } = useQuery(["stages"], () => Onboarding.stages());
  const { data: checklist } = useQuery(["checklist"], () =>
    Checklist.checklist()
  );

  useEffect(() => {
    if (data) {
      const sorted = data.sort((a, b) => a.position - b.position);
      const d = sorted.map((s) => {
        const currentStage = data.find((s) => s.id === user?.current_stage_id);

        // If the stage is still locked
        if (s.days_remaining_to_unlock > 0) {
          return {
            ...s,
            isLocked: true,
          };
        }

        // If the user is on or after this stage in the journey
        if (currentStage) {
          return {
            ...s,
            isLocked: currentStage.position < s.position,
          };
        }

        // If the user is at the start of the journey
        return {
          ...s,
          isLocked: s.next_stage === false,
        };
      });

      setStages(d);
    }
  }, [data, user?.current_stage_id]);

  useEffect(() => {
    revalidateSession();
  }, []);

  const calcStageProgress = (stage: Stage) => {
    const r = 19;
    const circumference = r * 2 * Math.PI;
    const percentage = stage.tasks.filter((t) => t.completed);

    return {
      strokeDasharray: `${circumference}px ${circumference}px`,
      strokeDashoffset: `${
        circumference - (percentage.length / stage.tasks.length) * circumference
      }px`,
    };
  };

  return (
    <IonPage>
      <Header logo={user?.styles.logo_url}></Header>
      <IonContent fullscreen>
        {isLoading ? (
          <div className="flex justify-center py-12">
            <IonSpinner />
          </div>
        ) : (
          <div className="mx-auto max-w-3xl">
            {checklist && user && !user.checklist_completed && (
              <IonItem lines="none">
                <IonRouterLink className="w-full mt-4" routerLink="/checklist">
                  <div className="flex space-x-2 items-center w-full p-4 rounded-md text-white bg-button">
                    <IonIcon icon={clipboard} />
                    <div>{checklist.name}</div>
                  </div>
                </IonRouterLink>
              </IonItem>
            )}
            <div className="grid md:grid-cols-2 gap-y-4 md:gap-y-8 py-4 md:py-8">
              {stages?.map((s, i) => (
                <IonItem
                  key={s.id}
                  className="rounded-md h-[360px] overflow-hidden"
                  lines="none"
                >
                  {!s.isLocked ? (
                    <IonRouterLink
                      className="w-full"
                      routerLink={`/onboarding/task/${
                        s.tasks.find((t) => !t.completed)?.id ??
                        s.tasks[s.tasks.length - 1].id
                      }`}
                    >
                      <div className="h-[360px] bg-black bg-opacity-20 rounded-md overflow-hidden relative">
                        <div className="bg-gradient-to-t from-black absolute left-0 top-0 rounded-md opacity-80 w-full h-full"></div>
                        <img
                          className=" w-full h-full object-cover"
                          src={s.image}
                          alt={s.name}
                        />
                        <div className="absolute bottom-5 px-5 flex space-x-6 items-end justify-between w-full">
                          <IonText className="text-xl text-white font-medium">
                            {s.name}
                          </IonText>
                          <div
                            className={cn(
                              "flex-shrink-0 h-10 w-10 relative flex justify-center items-center border-2 rounded-full text-white text-xl",
                              !s.completed && "border-opacity-30"
                            )}
                          >
                            {s.completed ? (
                              <IonIcon icon={checkmark} />
                            ) : (
                              <svg className="progress-ring absolute h-10 w-10">
                                <circle
                                  style={{
                                    strokeDasharray:
                                      calcStageProgress(s).strokeDasharray,
                                    strokeDashoffset:
                                      calcStageProgress(s).strokeDashoffset,
                                  }}
                                  className="progress-ring-circle transform -rotate-90 origin-center"
                                  strokeWidth="2"
                                  fill="transparent"
                                  stroke="white"
                                  r="19"
                                  cx="20"
                                  cy="20"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                    </IonRouterLink>
                  ) : (
                    <IonRouterLink className="w-full">
                      <div className="relative h-[360px] bg-black bg-opacity-20 rounded-md overflow-hidden">
                        <div className="absolute left-0 top-0 rounded-md bg-black bg-opacity-75 w-full h-full"></div>
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-9xl">
                          <IonIcon icon={lockClosed}></IonIcon>
                        </div>
                        <img
                          className="rounded-md w-full h-[400px] object-cover bg-blue-100"
                          src={s.image}
                          alt={s.name}
                        />
                        <IonText className="text-xl absolute bottom-5 left-5 text-white font-medium w-4/5">
                          {t("stage.locked_title")}
                        </IonText>
                      </div>
                    </IonRouterLink>
                  )}
                </IonItem>
              ))}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;
