import { client } from "src/utils/api";

class Stages {
  static async stages(): Promise<Stage[]> {
    const res = await client("/stages", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sess")}`,
      },
    });
    return res.data;
  }

  static async stage(id: number | string): Promise<Stage> {
    if (!id) throw Error("No id provided");

    const res = await client(`/stages/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sess")}`,
      },
    });

    return res.data;
  }
}

export default Stages;
