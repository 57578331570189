import { client } from "src/utils/api";

class PostOnboarding {
  static async postOnboardables(): Promise<PostOnboardable[]> {
    const res = await client("/feed", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sess")}`,
      },
    });
    return res.data;
  }

  static async feeds(id?: string): Promise<any[]> {
    const res = await client(`/feed/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sess")}`,
      },
    });
    return res.data;
  }
}

export default PostOnboarding;
