import { RouteComponentProps } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "src/context/auth";
import {
  IonContent,
  IonPage,
  IonButton,
  IonText,
  IonRouterLink,
} from "@ionic/react";
import { Header } from "src/components/Ui";

const Qr: React.FC<RouteComponentProps> = ({ history }) => {
  const { setUser, isLoading } = useContext(AuthContext);

  const signOut = async () => {
    localStorage.removeItem("sess");
    setUser(null);
    if (!isLoading) history.push("/login");
  };
  const { user } = useContext(AuthContext);

  interface IData {
    id: number;
    title: string;
    description: string;
    img: string;
    background?: string;
  }

  const myData: IData[] = [
    {
      id: 0,
      title: "Hago Zorg Directe medewerkers",
      description: "Ik werk voor Hago Zorg Direct in de operatie.",
      img: "./assets/frame01.png",
      background: "bg-red-500",
    },
    {
      id: 1,
      title: "Hago zorg Indirect",
      description: "Ik doorloop de reis voor indirecte medewerkers.",
      img: "./assets/frame02.png",
      background: "bg-purple-500",
    },
  ];

  return (
    <IonPage>
      <Header logo={user?.styles.logo_url}></Header>
      <IonContent fullscreen>
        <div className="container mx-auto max-w-5xl p-4 flex flex-col text-center mt-20">
          <IonText className="text-4xl">Kies je afdeling</IonText>
          <IonText className="text-xl md:w-1/2 mx-auto">
            Leuk dat je hier terechtgekomen bent. Bij welke afdeling hoor je?
          </IonText>
        </div>
        <div className="container  mx-auto max-w-3xl p-4 flex flex-col bg-white">
          {myData.map((item) => {
            return (
              <IonRouterLink
                href="/onboarding"
                key={item.id}
                className={`${item.background} flex overflow-hidden relative h-48 w-full  p-4 mb-4 items-end rounded-xl shadow-lg`}
              >
                <img
                  className="h-full absolute left-0 top-0 w-full object-cover opacity-75 rounded-xl animate-pulse"
                  src={item.img}
                />
                <h3 className="z-50 text-white">{item.title}</h3>
              </IonRouterLink>
            );
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Qr;
