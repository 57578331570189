import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IonPage, IonContent } from "@ionic/react";
import { PostOnboarding } from "src/api";
import { useQuery } from "react-query";
import { Header } from "src/components/Ui";

import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

interface Params {
  id?: string;
}

const Feed: React.FC<RouteComponentProps<Params>> = ({ match, history }) => {
  const [index, setIndex] = useState<number>(0);
  const { data }: any = useQuery(
    ["feed", match.params.id],
    () => PostOnboarding.feeds(match.params.id),
    { enabled: !!match.params.id }
  );

  return (
    <IonPage>
      <Header title={"Feed"}></Header>
      <IonContent>
        {data && (
          <div className="container  mx-auto max-w-3xl p-4 flex flex-col bg-white">
            <h2>{data?.title}</h2>
            <div>{data?.body}</div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Feed;
