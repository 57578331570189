import { IonCheckbox, IonLabel } from "@ionic/react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

interface CheckboxProps {
  control: Control<any>; // any because the field value generic from the parent won't be available
  name: string;
  label: string;
  value?: string;
  className?: string;
  rules?: RegisterOptions;
  error?: string;
  labelPosition?: "fixed" | "floating" | "stacked";
}

function Checkbox({ control, name, className, rules, label }: CheckboxProps) {
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, ref, value } }) => {
        return (
          <>
            <IonCheckbox
              className={className}
              id={name}
              onIonChange={(e) => onChange(e.detail.checked)}
              checked={value}
              ref={ref}
            />
            <IonLabel className="ml-2">{label}</IonLabel>
          </>
        );
      }}
    />
  );
}

export default Checkbox;
