import { IonHeader, IonText, IonIcon } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

interface OnboardingHeaderProps {
  task: OnboardingTask;
}

function OnboardingHeader({ task }: OnboardingHeaderProps) {
  return (
    <IonHeader className="bg-header">
      <div className="flex justify-between items-center">
        <IonIcon className="p-2" icon={chevronBackOutline} size="large" />
        <IonText className="font-medium text-lg">{task.name}</IonText>
        <IonIcon className="p-2" icon={chevronForwardOutline} size="large" />
      </div>
      <div></div>
    </IonHeader>
  );
}

export default OnboardingHeader;
