import {
  IonItem,
  IonAccordion,
  IonLabel,
  IonAccordionGroup,
} from "@ionic/react";
import { Text } from "src/components/Ui";

interface TipCollectionTaskProps {
  task: OnboardingTask;
}

function TipCollectionTask({ task }: TipCollectionTaskProps) {
  return (
    <div>
      <div className="px-4">
        <Text className="render-html">{task.text}</Text>
      </div>
      <IonAccordionGroup>
        {task.questions?.map((q) => (
          <IonAccordion value={q.question} key={q.id}>
            <IonItem slot="header">
              <IonLabel>{q.question}</IonLabel>
            </IonItem>

            <IonItem lines="none" slot="content">
              <Text className="render-html">{q.answer}</Text>
            </IonItem>
          </IonAccordion>
        ))}
      </IonAccordionGroup>
    </div>
  );
}

export default TipCollectionTask;
