import { useMemo } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IonContent,
  IonPage,
  IonText,
  IonHeader,
  IonIcon,
  IonItem,
  IonImg,
  IonThumbnail,
} from "@ionic/react";
import { chevronBackOutline, mailOutline, person } from "ionicons/icons";
import Network from "src/api/network";
import { useQuery } from "react-query";

const Contacts: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { data: contacts } = useQuery(["contacts"], () => Network.contacts());

  const sortedContacts = useMemo<
    { char: string; contacts: Contact[] }[]
  >(() => {
    if (contacts) {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const sorted = [];
      for (const l of alphabet) {
        sorted.push({
          char: l,
          contacts: contacts.filter(
            (c) => c.first_name.toUpperCase().charAt(0) === l
          ),
        });
      }

      return sorted.filter((c) => c.contacts.length > 0);
    }

    return [];
  }, [contacts]);

  if (!contacts) return null;

  return (
    <IonPage>
      <IonHeader className="bg-header text-white shadow-none flex items-center justify-between ">
        <div className="p-2 pb-0 text-white" onClick={() => history.goBack()}>
          <IonIcon icon={chevronBackOutline} size="large" />
        </div>
        <IonText className="font-medium text-lg">{t("team.contacts")}</IonText>
        <div className="w-8"></div>
      </IonHeader>
      <IonContent fullscreen>
        {sortedContacts.length > 0 ? (
          <div>
            {sortedContacts.map((char) => (
              <IonItem key={char.char} className="flex flex-col">
                <div className="flex flex-col  w-full py-4">
                  <div className="bg-button mb-2 pl-4 text-white rounded-sm text-sm">
                    {char.char}
                  </div>
                  {char.contacts.map((c) => (
                    <div className="flex flex-row justify-between py-2">
                      <div className="flex flex-row items-center space-x-4">
                        {c.profile_picture_url ? (
                          <div className="rounded-full overflow-hidden">
                            <IonThumbnail slot="start">
                              <IonImg src={c.profile_picture_url} />
                            </IonThumbnail>
                          </div>
                        ) : (
                          <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center">
                            <IonIcon
                              className="text-gray-400 text-xl"
                              icon={person}
                            />
                          </div>
                        )}
                        <IonText>
                          {c.first_name} {c.last_name}
                        </IonText>
                      </div>
                      <a
                        href={`mailto:${c.email}`}
                        className="flex items-center"
                      >
                        <IonIcon icon={mailOutline} className="text-2xl" />
                      </a>
                    </div>
                  ))}
                </div>
              </IonItem>
            ))}
          </div>
        ) : (
          <div className="p-4 flex justify-center">
            <IonText>{t("network.no_contacts")}</IonText>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Contacts;
