import VideoTask from "./Video";
import TextTask from "./Text";
import BioTask from "./Bio";
import LinkCollection from "./LinkCollection";
import Message from "./Message";
import Question from "./Question";
import VideoCollection from "./VideoCollection";
import Timeline from "./Timeline";
import TeamPage from "./TeamPage";
import TipCollection from "./TipCollection";
import SubjectCollection from "./SubjectCollection";
import QuizQuestionCollection from "./QuizQuestionCollection";
import ContradictionsSentence from "./ContradictionsSentence";
import AskAQuestion from "./AskAQuestion";
import UploadVideo from "./UploadVideo";
import Embed from "./Embed";
import Custom1 from "./Custom1";

interface TaskProps {
  task: OnboardingTask;
}

function RenderTask({ task }: TaskProps) {
  switch (task.type) {
    case "Video":
      return <VideoTask task={task} />;
    case "Text":
      return <TextTask task={task} />;
    case "Bio":
      return <BioTask task={task} />;
    case "LinkCollection":
      return <LinkCollection task={task as OnboardingTaskLinkCollection} />;
    case "Message":
      return <Message task={task} />;
    case "Question":
      return <Question task={task} />;
    case "VideoCollection":
      return <VideoCollection task={task} />;
    case "Timeline":
      return <Timeline task={task} />;
    case "TeamPage":
      return <TeamPage task={task} />;
    case "TipCollection":
      return <TipCollection task={task} />;
    case "SubjectCollection":
      return <SubjectCollection task={task} />;
    case "QuizQuestionCollection":
      return <QuizQuestionCollection task={task} />;
    case "ContradictionsSentence":
      return <ContradictionsSentence task={task} />;
    case "AskAQuestion":
      return <AskAQuestion task={task} />;
    case "UploadVideo":
      return <UploadVideo task={task} />;
    case "Embed":
      return <Embed task={task} />;
    case "Custom1":
      return <Custom1 task={task} />;
    default:
      return null;
  }
}

export default RenderTask;
