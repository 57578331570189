interface EmbedTaskProps {
  task: OnboardingTask;
}

function EmbedTask({ task }: EmbedTaskProps) {
  return (
    <iframe
      title={task.name}
      className="w-full h-full"
      src={`https://admin.custobo.com/public${task.embed_location}`}
    />
  );
}

export default EmbedTask;
