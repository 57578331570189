import { useContext, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/auth";
import { Input } from "src/components/Form";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Auth } from "src/api";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonText,
  IonToast,
  IonRouterLink,
} from "@ionic/react";

interface FormData {
  email: string;
  password: string;
}

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [hasAgreedToPrivacy, setHasAgreedToPrivacy] = useState(false);
  const { setUser, isLoading } = useContext(AuthContext);
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<FormData>();
  const { mutateAsync, isError } = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      Auth.login(email, password)
  );

  const onSubmit = async ({ email, password }: FormData) => {
    const { data: user } = await mutateAsync({ email, password });
    localStorage.setItem("sess", user.api_token);
    setUser(user);

    if (!isLoading) {
      history.push("/welcome");
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        {hasAgreedToPrivacy ? (
          <>
            <IonToast
              isOpen={isError}
              message={t("login.invalid_credentials")}
              color="danger"
              ref={(el) => {
                el?.removeAttribute("tabindex");
              }}
            />
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">Login</IonTitle>
              </IonToolbar>
            </IonHeader>
            <div
              className="h-full bg-cover bg-center"
              style={{
                backgroundImage: `url("/assets/custobo_login_background.jpg")`,
              }}
            >
              <div className="container mx-auto max-w-3xl p-4 h-full flex justify-center items-center">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="mt-48 w-full"
                >
                  <div className="space-y-4 mb-4">
                    <Input
                      name="email"
                      control={control}
                      placeholder="Email"
                      type="email"
                      rules={{
                        required: true,
                      }}
                      className="bg-white rounded-md"
                    />
                    <Input
                      name="password"
                      control={control}
                      placeholder="Wachtwoord"
                      type="password"
                      rules={{
                        required: true,
                      }}
                      className="bg-white rounded-md"
                    />
                  </div>
                  {/* Ionic submit doesn't work when pressing enter */}
                  <button type="submit" className="hidden"></button>
                  <IonButton className="btn-login w-full mb-4" type="submit">
                    {t("login.login")}
                  </IonButton>
                  <IonRouterLink
                    routerLink="/forgot-password"
                    className="text-white flex justify-center"
                  >
                    {t("login.forgot_password")}
                  </IonRouterLink>
                  <div className="flex justify-center">
                    <IonText className="text-center text-white"></IonText>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div className="container mx-auto max-w-3xl p-4 space-y-4 pt-12">
            <IonText className="block text-2xl font-bold pt-4">
              Gegevens &amp; Privacy
            </IonText>
            <IonText className="block font-bold">
              Jouw privacy is belangrijk voor ons.
            </IonText>
            <IonText className="block">
              Belangrijk voor jou om te weten is dat we jouw naam, e-mail adres,
              telefoonnummer en verjaardag hebben versleuteld. We gebruiken je
              gegevens alleen voor het gebruik van de applicatie en delen deze
              niét met derden.
            </IonText>
            <IonText className="block">
              Mocht je nog vragen hebben, het privacybeleid willen nalezen of je
              willen afmelden voor deze applicatie, dan kan dat altijd via de
              instellingen knop in de menubalk van deze applicatie.
            </IonText>

            <IonButton
              className="btn-login w-full"
              onClick={() => setHasAgreedToPrivacy(true)}
            >
              Akkoord
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Login;
