import { IonText } from "@ionic/react";
import cn from "classnames";
import Showdown from "showdown";

interface TextProps {
  children: string;
  className?: string;
}

const converter = new Showdown.Converter({ tables: true });

function Text({ children, className }: TextProps) {
  return (
    <IonText
      className={cn(className)}
      dangerouslySetInnerHTML={{ __html: converter.makeHtml(children) }}
    />
  );
}

export default Text;
