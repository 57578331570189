import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          general: {
            sure: "Are you sure?",
            yes: "Yes",
            no: "No",
            sure_task:
              "Are you sure you want to go back? All unsaved changes will be lost.",
          },
          login: {
            email: "Email",
            password: "Password",
            login: "Next",
            forgot_password: "Forgot password",
            agree: "Agree",
            invalid_email: "Invalid email",
            invalid_password: "Invalid password",
            invalid_credentials: "Invalid username or password",
            back_to_login: "Back to login",
            reset_password_cta: "Reset password",
            reset_password_instructions:
              "You'll receive an email with instructions on how to reset your password.",
            privacy_title: "Privacy",
            privacy_text:
              "**Your privacy is important to us!**\n\nVia the ‘settings button’ in the menu bar in the footer of this application you can read the extensive privacy policy.\n\n**Important information**\n\nWe only use your personal information for the purpose for which you have shared it with us.\n\nWe only share personal information with external parties to enable our services, to enable our operational activities or when we are legally obliged to do so. In all other cases, we ask for your permission.\n\nYou can always ask us what we know about you and possibly correct incorrect personal information.\n\n**Communication**\n\nAs part of our service we inform you via e-mail, push notifications or SMS. In addition, you can choose whether you want us to continue to inform you about our products and services, including e-mails about new functionality and / or product modifications. If you no longer wish to receive these e-mails, you can simply inform us\n\nThose were the most important things.",
          },
          form: {
            min_length: {
              one: "Please fill in.",
              many: "A minimum of {{count}} characters is required.",
            },
            explanation_required: "Fields with a * are required.",
            required: "This field is required.",
          },
          feedback: {
            title: "Feedback form",
            submit: "Submit",
            description: "A new feedback form is ready. Fill it in.",
            fill_in: "Fill in",
          },
          customer_selection: {
            title: "Company",
            description: "Select the company below.",
          },
          onboardables_selection: {
            title: "Company",
            description: "Select the company below.",
          },
          profile: {
            title: "Your profile",
            description: "Adjust the answers to your questions here.",
            no_questions:
              "No questions found, your profile is already complete!",
            button_text: "Save",
            saving: "Your profile is being saved",
          },
          profile_questions: {
            title: "Fill in your profile",
            button_text: "Next",
          },
          onboarding: {
            "next-button": "Next",
            "previous-button": "Previous",
            save: "Save",
            checklist: "Onboarding checklist",
            checklist_completed: "Awaiting checklist feedback",
            checklist_approved: "Checklist approved",
            checklist_fetching: "Refreshing checklist feedback...",
            checklist_failed_title: "Onboarding checklist",
            checklist_failed_description:
              "Your checklist is not approved, try again. Questions? Ask your supervisor.",
            checklist_failed_cta: "Open checklist",
            checklist_success_title: "Onboarding checklist",
            checklist_success_description:
              "Your checklist is approved. Job well done!",
            checklist_success_cta: "Back to dashboard",
            checklist_saving: "Checklist is being saved",
          },
          team: {
            my_team: "My team",
            contacts: "Contacts",
            other: "Featured teams",
          },
          bio: {
            save: "Save biography",
            saving: "Biography is being saved",
            profile_picture: "Profile picture",
            rewards: "Rewards",
            checklists: "Checklists",
          },
          message: {
            timeslot_explanation:
              "Select a date and time to send a calendar proposal:",
            confirm_timeslot:
              "A calendar proposal is being sent for {{date}} at {{time}} o’clock. Ok?",
            saving: "Calendar prosposal sent",
          },
          question: {
            saving: "Answer is being saved",
            saved: "Answer saved",
          },
          contradictions_sentence: {
            saving: "Your top-secret preferences are being saved ;)",
          },
          ask_a_question: {
            saving: "We’ll send your question to an expert",
          },
          picture: {
            upload_picture: "Upload picture",
            take_picture: "Take picture",
          },
          stage: {
            view: "View",
            locked_title: "Just hang tight!",
            locked_text:
              "The next phase will be unlocked quickly. We’ll send you a message when it’s time.",
            locked_button: "I’ll wait with baited breath",
          },
          feed: {
            title: "News",
          },
          notification: {
            title: "Notifications",
            no_notifications: "No notifications at the moment.",
          },
          network: {
            title: "Your team!",
            intro_prose:
              "<p>  Navigate to <strong>My Team</strong> and you’ll see your team members.\n  The button <strong>Contacten</strong> brings you to a summary of all\n  your coworkers!\n</p>\n",
            contacts: "Contacts",
            no_contacts: "No contacts",
            employee: "Employee",
            mail: "Email",
            call: "Call",
            phone: "Phone",
            professional_bio: "Professional",
            personal_bio: "Personal",
            specialties: "Specialities",
            bio_call_to_action: "Get to know {{first_name}} better!",
            no_team_members: "No team members",
            team_info: "Team Info",
            birthdate: "Birthday",
            day: "Dag",
            month: "Maand",
            no_answer: "{{first_name}} didn’t fill in an answer just yet.",
            contradictions_sentence:
              "What you didn’t know yet about {{first_name}}",
            search: "Search",
            linkedin: "LinkedIn",
            job_title: "Job title",
            is_fetching: "Contacts are loading...",
            interests: "Interests",
          },
          settings: {
            title: "Settings",
            delete_account: "Delete account",
            privacy_statement: "Privacy statement",
            notification_preferences: "Notification preferences",
            notification_preferences_description:
              "How do you want to receive your notifications?",
            notification_preferences_update: "Update",
            notification_preferences_updating: "Updating preferences...",
            custom_icon: "Set custom icon",
            logout: "Logout",
          },
          test: {
            title: "Quiz time!",
            is_fetching: "Quiz is loading.",
            no_test: "This quiz does not exist.",
            incorrect_answer: "That’s not the right answer. Try again.",
            correct_answers: "Good job! You entered all answers correct.",
            correct_answers_title: "Congratulations!",
            correct_answers_button: "Next",
            submit: "I’m sure!",
            wrong: "Wrong answer",
          },
          errors: {
            general_title: "Oops, something went wrong!",
            general_message:
              "Er is iets fout gegaan. We helpen je graag weer op weg. Kies één van onderstaande opties:",
            go_back: "Go back",
            go_to_dashboard: "Go to the Dashboard",
            unknown: {
              title: "Unknown error",
              message: "An unknown error occured.",
            },
            authorization: {
              title: "Authorization error",
              message: "An authorization error occured.",
            },
            validation: {
              title: "Validation error",
              message: "A validation error occured.",
            },
            server: {
              title: "Server error",
              message: "A server error occured.",
            },
            network: {
              title: "Network error",
              message: "A network error occured.",
            },
          },
          visitor: {
            title: "Submit a visitor",
          },
        },
      },
      nl: {
        translation: {
          general: {
            sure: "Weet je het zeker?",
            yes: "Ja",
            no: "Nee",
            sure_task:
              "Weet je zeker dat je terug wilt gaan? Alle niet opgeslagen wijzigingen zullen verloren gaan.",
          },
          login: {
            email: "Email",
            password: "Wachtwoord",
            login: "Verder",
            forgot_password: "Wachtwoord vergeten",
            agree: "Akkoord",
            invalid_email: "Email ongeldig",
            invalid_password: "Password ongeldig",
            invalid_credentials: "Ongeldige gebruikersnaam of wachtwoord",
            back_to_login: "Terug naar login",
            reset_password_cta: "Reset wachtwoord",
            reset_password_instructions:
              "Je ontvangt een e-mail met instructies hoe je je wachtwoord opnieuw kan instellen.",
            privacy_title: "Gegevens & Privacy",
            privacy_text:
              "**Jouw privacy is belangrijk voor ons.**\n\nBelangrijk voor jou om te weten is dat we jouw naam, e-mail adres, telefoonnummer en verjaardag hebben versleuteld. We gebruiken je gegevens alleen voor het gebruik van de applicatie en delen deze niét met derden. \n\nMocht je nog vragen hebben, het privacybeleid willen nalezen of je willen afmelden voor deze applicatie, dan kan dat altijd via de instellingen knop in de menubalk van deze applicatie.",
          },
          form: {
            min_length: {
              one: "Vul hieronder je reactie in",
              many: "Vul minimaal {{count}} tekens in.",
            },
            explanation_required: "Velden met een * zijn verplicht.",
            required: "Dit veld is verplicht.",
          },
          feedback: {
            title: "Feedback formulier",
            submit: "Gereed",
            description:
              "Er staat een nieuw feedback formulier voor je klaar. Vul het nu in.",
            fill_in: "Vul in",
          },
          customer_selection: {
            title: "Bedrijf",
            description: "Selecteer hieronder het bedrijf.",
          },
          onboardables_selection: {
            title: "Bedrijf",
            description: "Selecteer hieronder het bedrijf of de afdeling.",
          },
          profile: {
            title: "Je profiel",
            description: "Pas hier je antwoorden op de vragen aan.",
            no_questions: "Geen vragen gevonden, je profiel is al compleet!",
            button_text: "Opslaan",
            saving: "Je profiel wordt opgeslagen",
          },
          profile_questions: {
            title: "Vul je profiel in",
            button_text: "Verder",
          },
          onboarding: {
            "next-button": "Verder",
            "previous-button": "Terug",
            save: "Opslaan",
            checklist: "Onboarding checklist",
            checklist_completed: "Wachten op checklist goedkeuring",
            checklist_approved: "Checklist is goedgekeurd",
            checklist_fetching: "Checklist verversen...",
            checklist_failed_title: "Onboarding checklist",
            checklist_failed_description:
              "Je checklist is niet goedgekeurd, rond deze alsnog af. Heb je vragen? Stel ze aan je leidinggevende.",
            checklist_failed_cta: "Naar checklist",
            checklist_success_title: "Onboarding checklist",
            checklist_success_description:
              "Checklist goedgekeurd, goed gedaan!",
            checklist_success_cta: "Naar dashboard",
            checklist_saving: "De checklist wordt opgeslagen",
          },
          team: {
            my_team: "Mijn team",
            contacts: "Contacten",
            other: "Uitgelichte teams",
          },
          bio: {
            save: "Opslaan biografie",
            saving: "Bio wordt opgeslagen",
            profile_picture: "Profielfoto",
            rewards: "Beloningen",
            checklists: "Checklists",
          },
          message: {
            timeslot_explanation:
              "Selecteer een datum en tijdstip om een agendavoorstel te versturen:",
            confirm_timeslot:
              "Er wordt een agendavoorstel verstuurd voor {{date}} om {{time}} uur. Ok?",
            saving: "Agendavoorstel verstuurd",
          },
          question: {
            saving: "Antwoord wordt opgeslagen",
            saved: "Antwoord is opgeslagen",
          },
          contradictions_sentence: {
            saving: "Jouw top-secret voorkeuren worden opgeslagen ;)",
          },
          ask_a_question: {
            saving: "We leggen je vraag neer bij een expert",
          },
          picture: {
            upload_picture: "Upload foto uit gallery",
            take_picture: "Maak foto met camera",
          },
          stage: {
            view: "Bekijk",
            locked_title: "Nog even geduld!",
            locked_text:
              "De volgende fase zal snel ontgrendeld worden. We sturen je een berichtje als het zo ver is.",
            locked_button: "Ik wacht in spanning af",
          },
          feed: {
            title: "Nieuws",
          },
          notification: {
            title: "Meldingen",
            no_notifications: "Er zijn op dit moment geen meldingen.",
          },
          network: {
            title: "Jouw team!",
            intro_prose:
              "<p>  Bij <strong>Mijn Team</strong> vind je je teamleden.\n  Daarnaast tref je onder de knop <strong>Contacten</strong> een overzicht van al\n  je collega's!\n</p>\n",
            contacts: "Contacten",
            no_contacts: "Geen contacten",
            employee: "Medewerker",
            mail: "Mail",
            call: "Bel",
            phone: "Telefoonnummer",
            professional_bio: "Professioneel",
            personal_bio: "Persoonlijk",
            specialties: "Specialiteiten",
            bio_call_to_action: "Leer {{first_name}} beter kennen!",
            no_team_members: "Geen teamleden",
            team_info: "Team Info",
            birthdate: "Verjaardag",
            day: "Day",
            month: "Month",
            no_answer: "{{first_name}} heeft nog geen antwoord ingevuld.",
            contradictions_sentence: "Wat je nog niet wist over {{first_name}}",
            search: "Zoeken",
            linkedin: "LinkedIn",
            job_title: "Functie",
            is_fetching: "Contacten worden geladen...",
            interests: "Interesses",
          },
          settings: {
            title: "Instellingen",
            delete_account: "Account verwijderen",
            privacy_statement: "Privacyverklaring",
            notification_preferences: "Notificatie voorkeuren",
            notification_preferences_description:
              "Op welke manier wil jij graag je notificaties ontvangen?",
            notification_preferences_update: "Bijwerken",
            notification_preferences_updating: "Bezig met bijwerken...",
            custom_icon: "Stel aangepast icoon in",
            logout: "Uitloggen",
          },
          test: {
            title: "Quiz time!",
            is_fetching: "Test wordt geladen.",
            no_test: "Deze test bestaat niet.",
            incorrect_answer:
              "Dit is niet het juiste antwoord. Probeer het nog eens.",
            correct_answers:
              "Goed zo! Je hebt alle antwoorden correct ingevuld.",
            correct_answers_title: "Gefeliciteerd!",
            correct_answers_button: "Verder",
            submit: "Ik weet het zeker!",
          },
          errors: {
            general_title: "Oeps, er is iets fout gegaan!",
            general_message:
              "Er is iets fout gegaan. We helpen je graag weer op weg. Kies één van onderstaande opties:",
            go_back: "Ga terug",
            go_to_dashboard: "Ga naar het Dashboard",
            unknown: {
              title: "Onbekende fout",
              message: "Er is een onbekende fout opgetreden.",
            },
            authorization: {
              title: "Autorisatie fout",
              message: "Er is een autorisatie fout opgetreden.",
            },
            validation: {
              title: "Validatie fout",
              message: "Er is een validatie fout opgetreden.",
            },
            server: {
              title: "Server fout",
              message: "Er is een server fout opgetreden.",
            },
            network: {
              title: "Netwerk fout",
              message: "Er is een netwerk fout opgetreden.",
            },
          },
          visitor: {
            title: "Bezoeker registreren",
          },
        },
      },
    },
  });

export default i18n;
