import { useMemo, useState } from "react";
import { IonCheckbox, IonLabel, IonItem, IonItemGroup } from "@ionic/react";
import { useController, Control } from "react-hook-form";

interface CheckboxesProps {
  question: ProfileQuestion;
  control: Control<any>;
  className?: string;
}

type Option = string | null;

interface MemoList {
  options: Option[];
  defaultValues?: any;
}

function Checkboxes({ control, question, className }: CheckboxesProps) {
  const { options, defaultValues } = useMemo((): MemoList => {
    const v = control._defaultValues[question.id.toString()];
    const options = question.input_options.split(",");
    if (v) {
      return {
        options,
        defaultValues: options.map((o: Option) => (v.includes(o) ? o : null)),
      };
    }
    return {
      options,
    };
  }, []);
  const { field } = useController({
    control,
    name: question.id.toString(),
  });
  const [val, setVal] = useState<Option[]>(defaultValues ?? []);

  return (
    <IonItemGroup className="w-full">
      <IonLabel position="stacked" className="pl-5 pb-4">
        {question.question}
      </IonLabel>
      {options.map((o, i) => (
        <IonItem lines="none" key={o}>
          <IonCheckbox
            slot="start"
            value={o}
            checked={val.includes(o)}
            onIonChange={({ detail: { checked } }) => {
              const temp: Option[] = [...val];
              temp[i] = checked ? o : null;
              field.onChange(temp);
              setVal(temp);
            }}
          />
          <IonLabel>{o}</IonLabel>
        </IonItem>
      ))}
    </IonItemGroup>
  );
}

export default Checkboxes;
