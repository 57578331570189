import { authClient, client } from "src/utils/api";

class Onboarding {
  static async onboardables(): Promise<Onboardable[]> {
    const res = await authClient().get("/onboardables");
    return res.data;
  }

  static async dashboard(): Promise<DashboardItem[]> {
    const res = await authClient().get("/dashboard");
    return res.data;
  }

  static async stages(): Promise<Stage[]> {
    const res = await authClient().get("/stages");
    return res.data;
  }

  static async test(id?: string): Promise<Test> {
    // Id maybe undefined because the id is provided in de route
    if (!id) throw Error("No id provided");

    const res = await authClient().get(`/tests/${id}`);

    return res.data;
  }

  static async completeTest(id: string): Promise<void> {
    await authClient().post(`/tests/${id}/complete`);
  }

  static async completeTask(id: string): Promise<void> {
    await authClient().post(`/tasks/${id}/complete`);
  }

  static async stagesId(id?: string): Promise<OnboardingTask> {
    // Id maybe undefined because the id is provided in de route
    if (!id) throw Error("No id provided");

    const res = await authClient().get(`/stages/${id}`);
    return res.data;
  }

  static async task(id?: string | number): Promise<OnboardingTask> {
    // Id maybe undefined because the id is provided in de route
    if (!id) throw Error("No id provided");

    const res = await authClient().get(`/tasks/${id}`);
    return res.data;
  }

  static async updateBio({
    personal_bio,
    professional_bio,
    specialties,
  }: {
    personal_bio: string;
    professional_bio: string;
    specialties: string;
  }) {
    const res = await client.post(
      `/session/update_bio`,
      {
        personal_bio,
        professional_bio,
        specialties,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sess")}`,
        },
      }
    );
    return res.data;
  }

  static async uploadQuestion({
    taskId,
    question,
  }: {
    taskId: number;
    question: string;
  }): Promise<any> {
    const res = await client.post(
      `/tasks/${taskId}/answer`,
      {
        selected: question,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sess")}`,
        },
      }
    );

    return res.data;
  }

  static async submitSubject({
    subjectId,
    question,
  }: {
    subjectId: string;
    question: string;
  }): Promise<any> {
    const res = await client.post(
      `/subjects/${subjectId}/question`,
      {
        subject_id: subjectId,
        question,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sess")}`,
        },
      }
    );
    return res.data;
  }

  static async submitSelectedSubject({
    subjectId,
    selected,
  }: {
    subjectId: string;
    selected: boolean;
  }): Promise<any> {
    const res = await client.post(
      `/subjects/${subjectId}/select`,
      { selected },
      { headers: { Authorization: `Bearer ${localStorage.getItem("sess")}` } }
    );

    return res.data;
  }
}

export default Onboarding;
