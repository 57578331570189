import type { RouteComponentProps } from "react-router-dom";
import { IonIcon, IonRouterLink, IonText, IonHeader } from "@ionic/react";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

const TeamPageTask: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();

  return (
    <div>
      <IonHeader className="bg-header text-white shadow-none flex items-center justify-between ">
        <div className="p-2 pb-0 text-white" onClick={() => history.goBack()}>
          <IonIcon icon={chevronBackOutline} size="large" />
        </div>
        <IonText className="font-medium text-lg">{t("team.my_team")}</IonText>
        <div className="w-8"></div>
      </IonHeader>
      <IonRouterLink routerLink="/network/team">
        <div className="flex justify-between border-t border-b p-4 items-center text-black">
          <span>{t("team.my_team")}</span>
          <IonIcon icon={chevronForwardOutline} />
        </div>
      </IonRouterLink>
      <IonRouterLink routerLink="/network/contacts">
        <div className="flex justify-between border-b p-4 items-center text-black">
          <span>{t("team.contacts")}</span>
          <IonIcon icon={chevronForwardOutline} />
        </div>
      </IonRouterLink>
      <IonRouterLink routerLink="/network/other">
        <div className="flex justify-between border-b p-4 items-center text-black">
          <span>{t("team.other")}</span>
          <IonIcon icon={chevronForwardOutline} />
        </div>
      </IonRouterLink>
    </div>
  );
};

export default TeamPageTask;
