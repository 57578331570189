import {
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonToast,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Onboarding } from "src/api";
import { useMutation } from "react-query";
import { Text } from "src/components/Ui";

interface QuestionTaskProps {
  task: OnboardingTask;
}

// TODO
function QuestionTask({ task }: QuestionTaskProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      question: task.answer,
    },
  });
  const { t } = useTranslation();
  const submitQuestion = useMutation(
    ({ taskId, question }: { taskId: number; question: string }) =>
      Onboarding.uploadQuestion({ taskId, question })
  );

  const submit = async ({ question }: any) => {
    try {
      await submitQuestion.mutateAsync({ taskId: task.id, question });
    } catch (err) {}
  };

  return (
    <div className="pt-4">
      <IonToast
        isOpen={submitQuestion.isSuccess}
        message={t("question.saved")}
        color="success"
        duration={2000}
        ref={(el) => {
          el?.removeAttribute("tabindex");
        }}
      />
      <div className="px-4">
        <Text className="render-html">{task.text}</Text>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <IonItem lines="none">
          <div className="w-full ">
            <IonLabel>
              <span
                className={cn(
                  "whitespace-normal",
                  errors.question && "text-red-600"
                )}
              >
                {t("form.min_length.one")}
              </span>
            </IonLabel>
            <IonTextarea
              className={cn(
                "border rounded w-full",
                errors.question && "border-red-600"
              )}
              id="question"
              rows={4}
              {...register("question", { required: true })}
            />
          </div>
        </IonItem>
        <IonItem lines="none">
          <div className="mt-8 w-full md:flex md:justify-end">
            <IonButton
              type="submit"
              className="btn-primary w-full md:w-48 h-12 mx-0"
            >
              {t("onboarding.save")}
            </IonButton>
          </div>
        </IonItem>
      </form>
    </div>
  );
}

export default QuestionTask;
