import type { History } from "history";
import { useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import Showdown from "showdown";
import { Checklist } from "src/api";
import { Header } from "src/components/Ui";
import { useTranslation } from "react-i18next";
import { AuthContext } from "src/context/auth";
import { checkmark } from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonText,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonButton,
  IonIcon,
  IonToast,
} from "@ionic/react";

const converter = new Showdown.Converter({ tables: true });

function ChecklistPage({ history }: { history: History }) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { control, handleSubmit, watch } = useForm();
  const { data } = useQuery(["checklist"], () => Checklist.checklist());
  const complete = useMutation(() => Checklist.complete());

  const submit = async () => {
    try {
      // The checklist complete request doesn't need data
      await complete.mutateAsync();
      history.push("/dashboard/home");
    } catch (err) {}
  };

  useEffect(() => {
    const subscription = watch((value) => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <IonPage>
      <Header title="test" goBack={() => history.goBack()} />
      <IonToast
        isOpen={complete.isLoading}
        message={t("onboarding.checklist_saving")}
        color="dark"
        ref={(el) => {
          el?.removeAttribute("tabindex");
        }}
      />
      <IonContent className="relative">
        {data && (
          <form onSubmit={handleSubmit(submit)}>
            <IonItem lines="none">
              <IonText
                className="render-html"
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(data.description),
                }}
              />
            </IonItem>
            {data.items.map((i) => (
              <Controller
                key={i.id}
                name={i.id.toString()}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <IonItem>
                    <IonLabel>{i.text}</IonLabel>
                    <IonCheckbox
                      checked={value}
                      onIonChange={async (e) => {
                        onChange(e.detail.checked);
                      }}
                    />
                  </IonItem>
                )}
              />
            ))}
            <IonItem lines="none">
              <div className="mt-4 w-full md:flex md:justify-end">
                <IonButton
                  type="submit"
                  className="btn-primary w-full md:w-48 h-12"
                >
                  {t("onboarding.save")}
                </IonButton>
              </div>
            </IonItem>
          </form>
        )}
        {user && user?.checklist_completed && (
          <div className="absolute top-0 left-0 bottom-0 right-0 bg-black z-30 bg-opacity-70 flex items-center justify-center">
            <div className="bg-button w-24 h-24 rounded-full flex items-center justify-center">
              <IonIcon icon={checkmark} size="large" className="text-white" />
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default ChecklistPage;
