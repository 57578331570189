import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  IonPage,
  IonContent,
  IonRouterLink,
  IonIcon,
  IonImg,
} from "@ionic/react";
import { useQuery } from "react-query";
import { Stages } from "src/api";
import { chevronForwardOutline } from "ionicons/icons";
import { Header, Text } from "src/components/Ui";

import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";

interface Params {
  id?: string;
}

const Stage: React.FC<RouteComponentProps<Params>> = ({
  match,
  history,
  location,
}) => {
  const { data: stage } = useQuery(
    ["stage", match.params.id],
    () => Stages.stage(match.params.id as string),
    { enabled: !!match.params.id }
  );

  if (!stage) return null;

  return (
    <IonPage>
      <Header title={stage.name} goBack={history.goBack}></Header>
      <IonContent fullscreen>
        <div className="container mx-auto max-w-5xl p-4">
          {stage.image && (
            <IonImg src={stage.image} className="h-48 object-cover mb-4" />
          )}
          {stage.intro && (
            <div className="mb-4">
              <Text>{stage.intro}</Text>
            </div>
          )}
          {stage.tasks.map((t) => (
            <IonRouterLink
              key={t.id}
              href={`/onboarding/task/${t.id}`}
              color="primary"
            >
              <div className="flex justify-between w-full border-b py-3 items-center text-black">
                <span className="flex-grow">{t.link_text}</span>
                <IonIcon icon={chevronForwardOutline} />
              </div>
            </IonRouterLink>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Stage;
