import { authClient } from "src/utils/api";

interface UpdateBioData {
  email: string;
  telephone: string;
  date_of_birth: string;
  job_title: string;
  linked_in_url: string;
  personal_bio: string;
  professional_bio: string;
  specialties: string;
}

interface RewardsData {
  id: number;
  description: string;
  icon: string;
  title: string;
  image: string;
}

class Profile {
  // Queries
  static async picture(): Promise<{ profile_picture: string }> {
    const res = await authClient().get("/session/profile_picture");
    return res.data;
  }

  // Mutations
  static async updateMany(list: [string, unknown][]): Promise<any> {
    const promises = list.map((item) => {
      return authClient().put(`/profile_questions/${item[0]}/answer`, {
        answer: item[1],
      });
    });

    return Promise.all(promises);
  }

  static async updatePicture(file: File): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    await authClient().post("/session/upload_profile_picture", formData);
  }

  static async updateBio(data: UpdateBioData): Promise<void> {
    await authClient().post("/session/update_bio", data);
  }

  static async updateInterests(data: UserInterestsItem[]): Promise<void> {
    await authClient().post("/session/update_interests", data);
  }

  /**
   * GET: User session
   */
  static async rewards(): Promise<RewardsData[]> {
    const res = await authClient().get("/rewards");
    return res.data;
  }
}

export default Profile;
